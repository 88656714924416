
import "./General.css"
import { useState, useEffect } from "react"
import axios from "axios"
import config from "../config.json"
import Cookies from "js-cookie";
import $ from 'jquery';
import DataTable from 'datatables.net';
import moment from 'moment';
import * as RemoteController from "../remoteControl"
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
// import { DateRangePicker } from './dist/index';
import { DateRangePicker } from 'react-date-range';
import * as rdrLocales from 'react-date-range/dist/locale';
import LoadingComponent from "../components/LoadingComponent"
import download from 'downloadjs';

function App() {
  const token = RemoteController.getToken()
  const [users, setUsers] = useState([])
  const [depositTransactions, setDepositTransactions] = useState([])
  const [transactionSummary, setTransactionSummary] = useState({})
  const [transactionSummaryWithdraw, setTransactionSummaryWithdraw] = useState({})
  const getProfileInformation = JSON.parse(localStorage.getItem('profile'));
  const remoteProfile = JSON.parse(localStorage.getItem('remoteProfile'));
  const isRemote = RemoteController.isControlling();
  const [showDateRangePicker, setShowDateRangePicker] = useState(false)
  const [showDateRangePickerExcel, setShowDateRangePickerExcel] = useState(false)
  const urlParams = new URLSearchParams(window.location.search);
  const startDate = urlParams.get('startDate') || new Date(moment().startOf('day'));
  const endDate = urlParams.get('endDate') || new Date(moment().endOf('day'));
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(startDate),
    endDate: new Date(endDate),
    key: 'selection',
  })
  const [excelLoading, setExcelLoading] = useState(false)
  const [selectionRangeExcel, setSelectionRangeExcel] = useState({
    startDate: new Date(startDate),
    endDate: new Date(endDate),
    key: 'selection',
  })




  const [state, setState] = useState({
    selectedUser: getProfileInformation?.type == 'website' ? getProfileInformation._id : 'all',
    startDate: new Date(moment().startOf('day')),
    endDate: new Date(moment().endOf('day')),
    commissionRate: 0,

  });

  const [stateExcel, setStateExcel] = useState({
  });

  const _setState = (obj) => {

    setState(prev => ({ ...prev, ...obj }))

  }

  const _setStateExcel = (obj) => {

    setStateExcel(prev => ({ ...prev, ...obj }))

  }


  const [mutabakat, setMutabakat] = useState({})

  const isPapara = localStorage.getItem('mode') == 'papara' ? true : false


  const getWebsiteControlUsers = async () => {
    const req = await axios.get(config.apiUrl + "users/website-users", {
      params: {
        token: token,
      }
    })
    setUsers(req.data)
  }


  useEffect(() => {
    getTransactionSummary()
  }, [state])
  useEffect(() => {
    getWebsiteControlUsers();

  }, [])




  const getTransactionSummary = async (selection) => {
    // setTransactionSummary({})
    const papara = await localStorage.getItem('mode') == 'papara' ? true : false
    if (getProfileInformation.type == "website") {
      const req = await axios.get(config.apiUrl + "users/mutabakat-website", {
        params: {
          token: token,
          papara,
          ...state,
          startDate: selection ? moment(selection.startDate).format('YYYY-MM-DD') : moment(selectionRange.startDate).format('YYYY-MM-DD'),
          endDate: selection ? moment(selection.endDate).format('YYYY-MM-DD') : moment(selectionRange.endDate).format('YYYY-MM-DD')
        }
      })
      setMutabakat(req.data)
    }
    else {
      const req = await axios.get(config.apiUrl + "users/mutabakat", {
        params: {
          token: token,
          papara,
          ...state,
          startDate: selection ? moment(selection.startDate).format('YYYY-MM-DD') : moment(selectionRange.startDate).format('YYYY-MM-DD'),
          endDate: selection ? moment(selection.endDate).format('YYYY-MM-DD') : moment(selectionRange.endDate).format('YYYY-MM-DD')
        }
      })
      setMutabakat(req.data)
    }


  }

  function formatMoney(n) {
    try {
      if (!n) return 0
      const x = parseFloat(n).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1.').replace(/\.(\d+)$/, ',$1');

      return x
    }
    catch (e) {
      return n
    }
  }

  useEffect(() => {


    const script = document.createElement("script");
    script.src = "YatirimDataTable.js";
    script.async = true;
    document.body.appendChild(script);
  }, [])

  const calculateCommission = (deposit, withdraw, commission) => {
    const amount = Number(deposit) - Number(withdraw)
    return `${formatMoney(amount)}TRY - ${formatMoney(amount * commission / 100)}TRY = ${formatMoney(amount - amount * commission / 100)}`

  }

  const dateLabel = moment(startDate).format("DD.MM.YYYY") == moment(endDate).format("DD.MM.YYYY") ? moment(endDate).format("DD.MM.YYYY") : `${moment(startDate).format("DD.MM.YYYY")} - ${moment(endDate).format("DD.MM.YYYY")}`
  const commissionRate = isRemote ? remoteProfile?.commissionRate : getProfileInformation?.commissionRate

  return (
    <div className="main-content">
      <div className="container-fluid">
        <div className="row clearfix">
          <div className="row" style={{ marginLeft: '10px' }}>
            {depositTransactions.length > 0 && <p>[{moment(depositTransactions[0].transactionTime).format("DD/MM/YYYY")} - {moment(depositTransactions[depositTransactions.length - 1].transactionTime).format("DD/MM/YYYY")}] -&gt; Gönderilen Tutar: {formatMoney(transactionSummary?.amount)} TL | İşlem Adedi: {transactionSummary?.number || 0} </p>}
          </div>
          <div style={{ width: '100%', marginBottom: '10px', padding: '10px' }}>






          </div>
          <div>

          </div>



          <div className="col-md-12">
            <div class="card">
              <h5 class="card-header">Excel Dökümü Al</h5>
              <div class="card-body" className=''>

                <div class="row pl-20 pr-20">



                  <div class="form-group col-md-2">
                    <label class="control-label">Tipi</label>
                    <div>
                      <select id="banka" value={state?.type} onChange={(e) => _setStateExcel({ type: e.target.value })} name="banka" class="form-control">
                        <option value="all">Hepsi</option>
                        <option value="deposit">Yatırım İşlemleri</option>
                        <option value="withdraw">Çekim İşlemleri</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group col-md-2">
                    <label class="control-label">Durumu</label>
                    <div>
                      <select id="banka" name="banka" value={state?.status} onChange={(e) => _setStateExcel({ status: e.target.value })} class="form-control">
                        <option value="all" >Hepsi</option>
                        <option value="1">Onaylanmış</option>
                        <option value="2">Reddedilmiş</option>
                      </select>
                    </div>
                  </div>



                  <div class="form-group col-md-2">
                    <label class="control-label">Website</label>
                    <div>
                      <input type="text" class="form-control input" name="" value={state?.website} onChange={(e) => _setStateExcel({ website: e.target.value })} />
                    </div>
                  </div>








                  <div class="form-group col-md-4">
                    <label class="control-label">Tarih Aralığı</label>
                    {showDateRangePickerExcel && <button className="btn btn-danger ml-10" onClick={() => setShowDateRangePickerExcel(false)}>X (Kapat)</button>}
                    {showDateRangePickerExcel && <><DateRangePicker
                      locale={rdrLocales.tr}
                      ranges={[selectionRange]}
                      onChange={(value) => {
                        setSelectionRangeExcel(value.selection);
                        setShowDateRangePickerExcel(false)
                      }}
                    /></>}
                    <div>
                      <button type="button" className="form-control input" onClick={() => setShowDateRangePickerExcel(prev => !prev)}>
                        {moment(selectionRangeExcel?.startDate).format('DD/MM-YYYY')} - {moment(selectionRangeExcel?.endDate).format('DD/MM-YYYY')} </button>
                    </div>
                  </div>


                  <div class="form-group col-md-2">
                    <label class="control-label">.</label>
                    <div>
                      <button className="btn btn-primary" style={{ width: 200 }} onClick={() => {
                        setExcelLoading(true)
                        //download excell
                        axios.post(config.apiUrl + 'transactions/excel', {
                          token: token,
                          filter: stateExcel,
                          startDate: moment(selectionRangeExcel?.startDate).format('YYYY-MM-DD'),
                          endDate: moment(selectionRangeExcel?.endDate).format('YYYY-MM-DD')
                        }, { responseType: 'blob' })
                          .then(async function (response) {
                            setExcelLoading(false)
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', 'file.xlsx');
                            document.body.appendChild(link);
                            link.click();

                          })

                      }}>Excel'i indir</button>
                    </div>
                  </div>




                </div>

              </div>
            </div>

          </div>





          <div className="col-md-12">
            <div class="card">
              <h5 class="card-header">İşlem Filtreleme</h5>
              <div class="card-body" className=''>

                <div class="row pl-20 pr-20">



                  <div class="form-group col-md-2">
                    <label class="control-label">Site</label>
                    <div>
                      <select id="banka" value={state?.type} onChange={(e) => _setState({ selectedUser: e.target.value, commissionRate: users?.find(q => q._id == e.target.value)?.commissionRate || 0 })} name="banka" class="form-control">
                        {getProfileInformation.type != 'website' && <option value="all">Hepsi</option>}
                        {getProfileInformation.type != 'website' && users.filter(q => q.type == "website").map((user, index) => <option value={user.parentAccount || user._id}> {user.website} - {user.firstName} {user.lastName}</option>)}
                        {getProfileInformation.type == 'website' && users.filter(q => q.type == "website" && getProfileInformation._id == q._id).map((user, index) => <option value={user.parentAccount || user._id}> {user.website} - {user.firstName} {user.lastName}</option>)}
                      </select>
                    </div>
                  </div>


                  <div class="form-group col-md-4">
                    <label class="control-label">Tarih Aralığı</label>
                    {showDateRangePicker && <button className="btn btn-danger ml-10" onClick={() => setShowDateRangePicker(false)}>X (Kapat)</button>}
                    {showDateRangePicker && <><DateRangePicker
                      locale={rdrLocales.tr}
                      ranges={[selectionRange]}
                      onChange={(value) => {
                        setSelectionRange(value.selection);
                        getTransactionSummary(value.selection)
                        // setShowDateRangePicker(false)
                        // window.location.href = '/?startDate=' + moment(value.selection.startDate).format('YYYY-MM-DD') + '&endDate=' + moment(value.selection.endDate).format('YYYY-MM-DD')

                      }}
                    /></>}
                    <div>
                      <button type="button" className="form-control input" onClick={() => setShowDateRangePicker(prev => !prev)}>
                        {moment(selectionRange?.startDate).format('DD/MM-YYYY')} - {moment(selectionRange?.endDate).format('DD/MM-YYYY')} </button>
                    </div>
                  </div>





                </div>

              </div>
            </div>

          </div>



          {getProfileInformation.type != 'website' && <div className="col-md-5">
            <div class="card">
              <h5 class="card-header">Genel Bilgiler</h5>
              <div class="card-body">

                <div class="row">


                  <div class="col-md-12 col-sm-12">
                    <div class="info-box bg-danger">
                      <span class="info-box-icon">
                        <i class="fas fa-money-bill-wave"></i>
                      </span>
                      <div class="info-box-content">
                        <span class="info-box-text">Kasa Durumu</span>
                        <span class="info-box-number">{formatMoney(isPapara ? mutabakat?.balancePapara : mutabakat?.balance)} TRY</span>
                      </div>
                    </div>
                  </div>





                </div>

              </div>
            </div>

          </div>}



          <div className="col-md-6">
            <div class="card">
              <h5 class="card-header">Bekleyen İşlemler</h5>
              <div class="card-body">

                <div class="row">


                  <div class="col-md-6 col-sm-6">
                    <div class="info-box bg-warning">
                      <span class="info-box-icon">
                        <i class="fas fa-money-bill-wave"></i>
                      </span>
                      <div class="info-box-content">
                        <span class="info-box-text">Beklenen Çekim İşlemleri</span>
                        <span class="info-box-number">{mutabakat?.waitingWithdraw} Adet</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-6">
                    <div class="info-box bg-success">
                      <span class="info-box-icon">
                        <i class="fas fa-money-bill-wave"></i>
                      </span>
                      <div class="info-box-content">
                        <span class="info-box-text">Bekleyen Yatırım İşlemleri </span>
                        <span class="info-box-number">{mutabakat?.waitingDeposit} Adet</span>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>




          <div className="col-md-6">
            <div class="card">
              <h5 class="card-header">{dateLabel} İşlem Kayıtları</h5>


              <div class="col-md-6 col-sm-6">

              </div>
              <div class="card-body">

                <div class="row">


                  <div class="col-md-6 col-sm-6">
                    <div class="info-box bg-danger">
                      <span class="info-box-icon">
                        <i class="fas fa-money-bill-wave"></i>
                      </span>
                      <div class="info-box-content">
                        <span class="info-box-text">Yatırım Adeti</span>
                        <span class="info-box-number">{mutabakat?.getTransactionSumDeposit?.number || 0} Adet</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-6">
                    <div class="info-box bg-success">
                      <span class="info-box-icon">
                        <i class="fas fa-money-bill-wave"></i>
                      </span>
                      <div class="info-box-content">
                        <span class="info-box-text">Çekim Adeti</span>
                        <span class="info-box-number">{mutabakat?.getTransactionSumWithdraw?.number || 0} Adet</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-6">
                    <div class="info-box bg-danger">
                      <span class="info-box-icon">
                        <i class="fas fa-money-bill-wave"></i>
                      </span>
                      <div class="info-box-content">
                        <span class="info-box-text">Yatırım Miktarı</span>
                        <span class="info-box-number">{mutabakat?.getTransactionSumDeposit?.amount || 0} TRY</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-6">
                    <div class="info-box bg-success">
                      <span class="info-box-icon">
                        <i class="fas fa-money-bill-wave"></i>
                      </span>
                      <div class="info-box-content">
                        <span class="info-box-text">Çekim Miktarı</span>
                        <span class="info-box-number">{mutabakat?.getTransactionSumWithdraw?.amount || 0} TRY</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-sm-12">
                    <div class="info-box bg-warning">
                      <span class="info-box-icon">
                        <i class="fas fa-money-bill-wave"></i>
                      </span>
                      <div class="info-box-content">
                        <span class="info-box-text">Komisyon</span>
                        <span class="info-box-number">{calculateCommission(mutabakat?.getTransactionSumDeposit?.amount || 0, mutabakat?.getTransactionSumWithdraw?.amount || 0, mutabakat?.commissionRate || 0)} TRY</span>
                      </div>
                    </div>
                  </div>





                </div>

              </div>
            </div>

          </div>

          <div className="col-md-6">
            <div class="card">
              <h5 class="card-header">Toplam İşlem Kayıtları</h5>
              <div class="card-body">

                <div class="row">


                  <div class="col-md-6 col-sm-6">
                    <div class="info-box bg-danger">
                      <span class="info-box-icon">
                        <i class="fas fa-money-bill-wave"></i>
                      </span>
                      <div class="info-box-content">
                        <span class="info-box-text">Yatırım Adeti</span>
                        <span class="info-box-number">{mutabakat?.getTransactionSumDepositAll?.number || 0} Adet</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-6">
                    <div class="info-box bg-success">
                      <span class="info-box-icon">
                        <i class="fas fa-money-bill-wave"></i>
                      </span>
                      <div class="info-box-content">
                        <span class="info-box-text">Çekim Adeti</span>
                        <span class="info-box-number">{mutabakat?.getTransactionSumWithdrawAll?.number || 0} Adet</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-6">
                    <div class="info-box bg-danger">
                      <span class="info-box-icon">
                        <i class="fas fa-money-bill-wave"></i>
                      </span>
                      <div class="info-box-content">
                        <span class="info-box-text">Yatırım Miktarı</span>
                        <span class="info-box-number">{mutabakat?.getTransactionSumDepositAll?.amount || 0} TRY</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-6">
                    <div class="info-box bg-success">
                      <span class="info-box-icon">
                        <i class="fas fa-money-bill-wave"></i>
                      </span>
                      <div class="info-box-content">
                        <span class="info-box-text">Çekim Miktarı</span>
                        <span class="info-box-number">{mutabakat?.getTransactionSumWithdrawAll?.amount || 0} TRY</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-sm-12">
                    <div class="info-box bg-warning">
                      <span class="info-box-icon">
                        <i class="fas fa-money-bill-wave"></i>
                      </span>
                      <div class="info-box-content">
                        <span class="info-box-text">Komisyon</span>
                        <span class="info-box-number">{calculateCommission(mutabakat?.getTransactionSumDepositAll?.amount || 0, mutabakat?.getTransactionSumWithdrawAll?.amount || 0, mutabakat?.commissionRate || 0)} TRY</span>
                      </div>
                    </div>
                  </div>





                </div>

              </div>
            </div>

          </div>



        </div>



      </div>

      {excelLoading && <LoadingComponent message="Dosya hazırlanıyor, Lütfen Bekleyin" />}


    </div>
  );
}

export default App;
