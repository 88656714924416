

import { useState, useEffect } from "react"
import axios from "axios"
import config from "../config.json"
import Cookies from "js-cookie";
import $ from 'jquery';
import DataTable from 'datatables.net';
import moment from 'moment';
import * as RemoteController from "../remoteControl"
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import * as rdrLocales from 'react-date-range/dist/locale';
import InfiniteScroll from "react-infinite-scroll-component";

function App() {
  const token = RemoteController.getToken()
  const [players, setPlayers] = useState([])
  const [transactionSummary, setTransactionSummary] = useState({})
  const [transactionSummaryWithdraw, setTransactionSummaryWithdraw] = useState({})
  const getProfileInformation = JSON.parse(localStorage.getItem('profile'));
  const [showDateRangePicker, setShowDateRangePicker] = useState(false)
  const urlParams = new URLSearchParams(window.location.search);
  const startDate = urlParams.get('startDate') || new Date(moment().startOf('day'));
  const endDate = urlParams.get('endDate') || new Date(moment().endOf('day'));
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(0);
  const [hasNext, setHasNext] = useState(true);
  const PAGE_SIZE = 30;
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(startDate),
    endDate: new Date(endDate),
    key: 'selection',
  });

  const [state, setState] = useState({
   
  });


  const _setState = (obj) => {

    setState(prev => ({ ...prev, ...obj }))

  }

  useEffect(() => {

    setIsLoading(true)
    getPlayers()



  }, [state])

  const getPlayers = async (_page) => {
    const papara = await localStorage.getItem('mode') == 'papara' ? true : false
    const params = {

      token: token,
      ...(Object.keys(state).length ? {filter: JSON.stringify(state)} : {}),
      papara,
      skip: _page * PAGE_SIZE,
      limit: PAGE_SIZE
    }
    console.log("Params", params)
    const req = await axios.get(config.apiUrl + "players/players", {
      params
    })


    
    if (!req?.data?.length) {
      setHasNext(false)
    }
    if (Array.isArray(req.data)) {
      setPlayers(prev => [...prev, ...req.data])
      console.log(req.data)

    }
    
        setPage((prev) => prev + 1)
  }


  const getTransactionSummary = async () => {
    const papara = await localStorage.getItem('mode') == 'papara' ? true : false
    setTransactionSummary({})
    const req = await axios.get(config.apiUrl + "transactions/gettransactionsummary", {
      params: {
        token: token,
        type: "deposit",
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        papara,
      }
    })
    setTransactionSummary(req.data)

    const req2 = await axios.get(config.apiUrl + "transactions/gettransactionsummary", {
      params: {
        token: token,
        type: "withdraw",
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
      }
    })
    setTransactionSummaryWithdraw(req2.data)
  }

  function formatMoney(n) {
    try {
      if (!n) return 0
      const x = parseFloat(n).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1.').replace(/\.(\d+)$/, ',$1');

      return x
    }
    catch (e) {
      return n
    }
  }

  useEffect(() => {
    // getDepositTransactions()
    getTransactionSummary()

    // const script = document.createElement("script");
    // script.src = "YatirimDataTable.js";
    // script.async = true;
    // document.body.appendChild(script);
  }, [])

  const validateRejectTransaction = async (status, _id, rejectReason = "") => {
    const req = await axios.post(config.apiUrl + "transactions/verifyrejectdeposit", {
      token: token,
      _id: _id,
      status: status ? 1 : 2,
      type: "deposit",
      rejectReason: rejectReason

    })
    window.location.reload()

  }



  return (
    <div className="main-content">
      <div className="container-fluid">
        <div className="row clearfix">
          {/* <div className="row" style={{ marginLeft: '10px' }}>
            {depositTransactions.length > 0 && <p>[{moment(depositTransactions[0].transactionTime).format("DD/MM/YYYY")} - {moment(depositTransactions[depositTransactions.length - 1].transactionTime).format("DD/MM/YYYY")}] -&gt; Gönderilen Tutar: {formatMoney(transactionSummary?.amount)} TL | İşlem Adedi: {transactionSummary?.number || 0} </p>}
          </div> */}
          <div style={{ width: '100%', marginBottom: '10px', padding: '10px' }}>

            <a href="manuelyatirim">
              {getProfileInformation.type == 'admin' && <button type="button" className="btn btn-primary" style={{ float: 'right', display: 'flex', justifyContent: 'end', marginRight: '110px' }}>
                Manuel Yatırım
              </button>}
            </a>

            <div className="col-md-12">
              <div class="card">
                <h5 class="card-header">Oyuncu Filtreleme</h5>
                <div class="card-body" className=''>

                  <div class="row pl-20 pr-20">



                    <div class="form-group col-md-2">
                      <label class="control-label">Tipi</label>
                      <div>
                        <select id="banka" value={state?.type} onChange={(e) => _setState({ type: e.target.value })} name="banka" class="form-control">
                          <option value="all">Hepsi</option>
                          <option value="deposit">Yatırım İşlemleri</option>
                          <option value="withdraw">Çekim İşlemleri</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group col-md-2">
                      <label class="control-label">Durumu</label>
                      <div>
                        <select id="banka" name="banka" value={state?.status} onChange={(e) => _setState({ status: e.target.value })} class="form-control">
                          <option value="all" >Hepsi</option>
                          <option value="1">Onaylanmış</option>
                          <option value="2">Reddedilmiş</option>
                        </select>
                      </div>
                    </div>

                    <div class="form-group col-md-2">
                      <label class="control-label">Kullanıcı Ad Soyad</label>
                      <div>
                        <input type="text" class="form-control input" name="" value={state?.nameSurname} onChange={(e) => _setState({ nameSurname: e.target.value })} />
                      </div>
                    </div>

                    <div class="form-group col-md-2">
                      <label class="control-label">Kullanıcı Id</label>
                      <div>
                        <input type="text" class="form-control input" name="" value={state?.user_id} onChange={(e) => _setState({ user_id: e.target.value })} />
                      </div>
                    </div>

                    <div class="form-group col-md-2">
                      <label class="control-label">Website</label>
                      <div>
                        <input type="text" class="form-control input" name="" value={state?.website} onChange={(e) => _setState({ website: e.target.value })} />
                      </div>
                    </div>

                    <div class="form-group col-md-1">
                      <label class="control-label">Tutar Min. </label>
                      <div>
                        <input type="number" class="form-control input" name="" value={state?.price_min} onChange={(e) => _setState({ price_min: e.target.value })} />
                      </div>
                    </div>

                    <div class="form-group col-md-1">
                      <label class="control-label">Tutar Max. </label>
                      <div>
                        <input type="number" class="form-control input" value={state?.price_max} onChange={(e) => _setState({ price_max: e.target.value })} name="" />
                      </div>
                    </div>




                    <div class="form-group col-md-4">
                      <label class="control-label">Tarih Aralığı</label>
                      {showDateRangePicker && <button className="btn btn-danger ml-10" onClick={() => setShowDateRangePicker(false)}>X (Kapat)</button>}
                      {showDateRangePicker && <><DateRangePicker
                        locale={rdrLocales.tr}
                        ranges={[selectionRange]}
                        onChange={(value) => {
                          setSelectionRange(value.selection);
                          getPlayers(page)
                          // setShowDateRangePicker(false)
                          // window.location.href = '/?startDate=' + moment(value.selection.startDate).format('YYYY-MM-DD') + '&endDate=' + moment(value.selection.endDate).format('YYYY-MM-DD')

                        }}
                      /></>}
                      <div>
                        <button type="button" className="form-control input" onClick={() => setShowDateRangePicker(prev => !prev)}>
                          {moment(selectionRange?.startDate).format('DD/MM-YYYY')} - {moment(selectionRange?.endDate).format('DD/MM-YYYY')} </button>
                      </div>
                    </div>





                  </div>

                </div>
              </div>

            </div>




          </div >

          <div className="col-md-12">
            <div class="card">
              <h5 class="card-header">İşlemler</h5>
              <div class="card-body" className=''>
              <InfiniteScroll
              dataLength={players.length} //This is important field to render the next data
              next={() => {
                getPlayers(page)
              }}
              hasMore={hasNext}
              loader={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img src="../img/loading.gif" class="rounded-circle" alt="" width={50} height={50} /></div>}
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>Sayfa Sonu</b>
                </p>
              }
            >
                <div class="row ml-20 mr-20" style={{ overflow: 'auto' }}>

               
            
                  <table id="example" className="table" style={{ width: '100%' }}>
                    <thead>
                      <tr>
                        <th>Website</th>
                        <th>AD SOYAD</th>
                        <th>YASKLILIK</th>
                        <th>İLK İŞLEM</th>
                        <th>SON İŞLEM</th>
                        <th>İŞLEM GEÇMİŞİ</th>
                      </tr>
                    </thead>
                    <tbody>
                   
                      {players.map((transaction, index) =>
                        <tr>
                          <td style={{ maxWidth: 200 }}>{transaction.webSiteCode}</td>
                          <td>{transaction.nameSurname}</td>
                          <td>{transaction.isBanned ? "Yasaklı" : "Yasaklı Değil"}</td>
                          <td>{moment(transaction.updatedAt).format("DD.MM.YYYY HH:MM")}</td>
                          <td>{moment(transaction.updatedAt).format("DD.MM.YYYY HH:MM")}</td>
                          <td>

                            {(getProfileInformation.type == 'super_admin') && <button type="button" onClick={() => window.location = '/admintransactionedit?_id=' + transaction._id} className="btn btn-info"><i className="ik ik-check-circle" />Detay</button>}


                          </td>
                        </tr>


                      )}


                    </tbody>
                  </table>
       
                </div>
                </InfiniteScroll>
              </div>
            </div>
          </div>


        </div >

      </div >
    </div >
  );
}

export default App;
