
import "./General.css"
import { useState, useEffect } from "react"
import axios from "axios"
import config from "../config.json"
import Cookies from "js-cookie";
import $ from 'jquery';
import DataTable from 'datatables.net';
import moment from 'moment';
import * as RemoteController from "../remoteControl"
import { PERMISSIONS, checkPermission } from "../consts/Permissions"



function App() {
  const userProfile = JSON.parse(localStorage.getItem('profile'))
  const token = Cookies.get('token')
  const [users, setUsers] = useState([])
  const [transactionSummary, setTransactionSummary] = useState({})
  const [selectedUser, setSelectedUser] = useState(null)
  const [formData, setFormData] = useState({
    type:"admin",
    username: "",
    firstName: "",
    lastName: "",
    password: "",
  })

  const deleteUserRequest = async (userId) => {
    const req = await axios.post(config.apiUrl + "users/delete", {

      token: token,
      remoteToken: localStorage.getItem('remoteToken'),
      _id: userId
    })
    window.location.reload();
  }

  const editAdminUser = async (userId) => {

    const req = await axios.post(config.apiUrl + "users/updateAdmin", {
      token: token,
      commissionRate: formData?.commissionRate,
      _id: userId
    })
    window.location.reload();
  }

  const getAllUsers = async () => {
    const req = await axios.get(config.apiUrl + "users", {
      params: {
        token: token,
      }
    })
    setUsers(req.data)
  }





  function formatMoney(n) {
    try {


      return parseFloat(n).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1.').replace(/\.(\d+)$/, ',$1');
    }
    catch (e) {
      return n
    }
  }



  const openUserEditModal = (user) => {
    // alert(userId)
    setSelectedUser(user)
    formHandler('commissionRate', user?.commissionRate)
  }

  useEffect(() => {
    getAllUsers()
    const script = document.createElement("script");
    script.src = "CekimIslemleri.js";
    script.async = true;
    document.body.appendChild(script);
  }, [])

  if (!(checkPermission(userProfile, PERMISSIONS.USER_MANAGEMENT.key) || checkPermission(userProfile, PERMISSIONS.USER_VIEW.key)))
    return (<>No permission</>)

  const formHandler = (inputName, value, number = false) => {
    setFormData(prev => {
      const newDataForm = new Object({ ...formData })
      if (number)
        newDataForm[inputName] = Number(value)
      else
        newDataForm[inputName] = value

      return newDataForm
    }

    )
  }


  const createUser = () => {
    axios.post(config.apiUrl + "users/createuser", {
      token: token,
      ...formData,
    }).then(() => { alert("Başarılı bir şekilde eklendi"); window.location.reload() }).catch(e => alert("Ekleme sırasında hata" + e))
  }


  return (
    <>
      <div className="main-content">
        <div className="container-fluid">
          <div className="">

            <div className="row upperSection">
              {users.length > 0 && <p> Toplam Kullanıcı : {users.length} </p>}
              <div style={{ marginLeft: 20 }}><a><button type="button" data-toggle="modal" data-target="#createUserModal" className="btn btn-info btn-rounded">Yeni Kullanıcı Aç</button></a></div>
            </div>


            <table id="example" className="display nowrap" style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>KULLANICI ADI</th>
                  <th>AD SOYAD</th>
                  <th>TÜR</th>
                  <th>İŞLEMLER</th>
                </tr>
              </thead>
              <tbody>

                {
                  users.filter(e => e.type != 'user').map((user, index) =>

                    <tr>
                      <td>{user._id}</td>
                      <td>{user.username}</td>
                      <td>{user.firstName} {user.lastName}</td>
                      <td>{user.type == 'super_admin' ? 'Süper Admin' : user.type == 'admin' ? 'Admin' : 'User'}</td>
                      {user._id != userProfile._id &&
                      <td className="buttonGroup">
                        <a onClick={() => RemoteController.connectRemote(user._id)}><button type="button" className="btn btn-info btn-rounded">Panele Git</button></a>
                        <a><button type="button" data-toggle="modal" data-target="#exampleModalLong" onClick={() => openUserEditModal(user)} className="btn btn-info btn-rounded">Düzenle</button></a>
                        <a onClick={() => deleteUserRequest(user._id)} style={{ marginLeft: 10 }}><button type="button" className="btn btn-danger btn-rounded">Sil</button></a>
                      </td>
                      }
                      {user._id == userProfile._id && <td></td>}
                    </tr>
                  )

                }

              </tbody>
            </table>
          </div>
          <aside className="right-sidebar">
            <div className="sidebar-chat" data-plugin="chat-sidebar">
              <div className="sidebar-chat-info">
                <h6>Chat List</h6>
                <form className="mr-t-10">
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Search for friends ..." />
                    <i className="ik ik-search" />
                  </div>
                </form>
              </div>
              <div className="chat-list">
                <div className="list-group row">
                  <a href="javascript:void(0)" className="list-group-item" data-chat-user="Gene Newman">
                    <figure className="user--online">
                      <img src="../img/users/1.jpg" className="rounded-circle" alt="" />
                    </figure><span><span className="name">Gene Newman</span>  <span className="username">@gene_newman</span> </span>
                  </a>
                  <a href="javascript:void(0)" className="list-group-item" data-chat-user="Billy Black">
                    <figure className="user--online">
                      <img src="../img/users/2.jpg" className="rounded-circle" alt="" />
                    </figure><span><span className="name">Billy Black</span>  <span className="username">@billyblack</span> </span>
                  </a>
                  <a href="javascript:void(0)" className="list-group-item" data-chat-user="Herbert Diaz">
                    <figure className="user--online">
                      <img src="../img/users/3.jpg" className="rounded-circle" alt="" />
                    </figure><span><span className="name">Herbert Diaz</span>  <span className="username">@herbert</span> </span>
                  </a>
                  <a href="javascript:void(0)" className="list-group-item" data-chat-user="Sylvia Harvey">
                    <figure className="user--busy">
                      <img src="../img/users/4.jpg" className="rounded-circle" alt="" />
                    </figure><span><span className="name">Sylvia Harvey</span>  <span className="username">@sylvia</span> </span>
                  </a>
                  <a href="javascript:void(0)" className="list-group-item active" data-chat-user="Marsha Hoffman">
                    <figure className="user--busy">
                      <img src="../img/users/5.jpg" className="rounded-circle" alt="" />
                    </figure><span><span className="name">Marsha Hoffman</span>  <span className="username">@m_hoffman</span> </span>
                  </a>
                  <a href="javascript:void(0)" className="list-group-item" data-chat-user="Mason Grant">
                    <figure className="user--offline">
                      <img src="../img/users/1.jpg" className="rounded-circle" alt="" />
                    </figure><span><span className="name">Mason Grant</span>  <span className="username">@masongrant</span> </span>
                  </a>
                  <a href="javascript:void(0)" className="list-group-item" data-chat-user="Shelly Sullivan">
                    <figure className="user--offline">
                      <img src="../img/users/2.jpg" className="rounded-circle" alt="" />
                    </figure><span><span className="name">Shelly Sullivan</span>  <span className="username">@shelly</span></span>
                  </a>
                </div>
              </div>
            </div>
          </aside>
          <div className="chat-panel" hidden>
            <div className="card">
              <div className="card-header d-flex justify-content-between">
                <a href="javascript:void(0);"><i className="ik ik-message-square text-success" /></a>
                <span className="user-name">John Doe</span>
                <button type="button" className="close" aria-label="Close"><span aria-hidden="true">×</span></button>
              </div>
              <div className="card-body">
                <div className="widget-chat-activity flex-1">
                  <div className="messages">
                    <div className="message media reply">
                      <figure className="user--online">
                        <a href="#">
                          <img src="../img/users/3.jpg" className="rounded-circle" alt="" />
                        </a>
                      </figure>
                      <div className="message-body media-body">
                        <p>Epic Cheeseburgers come in all kind of styles.</p>
                      </div>
                    </div>
                    <div className="message media">
                      <figure className="user--online">
                        <a href="#">
                          <img src="../img/users/1.jpg" className="rounded-circle" alt="" />
                        </a>
                      </figure>
                      <div className="message-body media-body">
                        <p>Cheeseburgers make your knees weak.</p>
                      </div>
                    </div>
                    <div className="message media reply">
                      <figure className="user--offline">
                        <a href="#">
                          <img src="../img/users/5.jpg" className="rounded-circle" alt="" />
                        </a>
                      </figure>
                      <div className="message-body media-body">
                        <p>Cheeseburgers will never let you down.</p>
                        <p>They'll also never run around or desert you.</p>
                      </div>
                    </div>
                    <div className="message media">
                      <figure className="user--online">
                        <a href="#">
                          <img src="../img/users/1.jpg" className="rounded-circle" alt="" />
                        </a>
                      </figure>
                      <div className="message-body media-body">
                        <p>A great cheeseburger is a gastronomical event.</p>
                      </div>
                    </div>
                    <div className="message media reply">
                      <figure className="user--busy">
                        <a href="#">
                          <img src="../img/users/5.jpg" className="rounded-circle" alt="" />
                        </a>
                      </figure>
                      <div className="message-body media-body">
                        <p>There's a cheesy incarnation waiting for you no matter what you palete preferences are.</p>
                      </div>
                    </div>
                    <div className="message media">
                      <figure className="user--online">
                        <a href="#">
                          <img src="../img/users/1.jpg" className="rounded-circle" alt="" />
                        </a>
                      </figure>
                      <div className="message-body media-body">
                        <p>If you are a vegan, we are sorry for you loss.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <form action="javascript:void(0)" className="card-footer" method="post">
                <div className="d-flex justify-content-end">
                  <textarea className="border-0 flex-1" rows={1} placeholder="Type your message here" defaultValue={""} />
                  <button className="btn btn-icon" type="submit"><i className="ik ik-arrow-right text-success" /></button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade apps-modal" id="appsModal" tabIndex={-1} role="dialog" aria-labelledby="appsModalLabel" aria-hidden="true" data-backdrop="false">
        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><i className="ik ik-x-circle" /></button>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="quick-search">
              <div className="container">
                <div className="row">
                  <div className="col-md-4 ml-auto mr-auto">
                    <div className="input-wrap">
                      <input type="text" id="quick-search" className="form-control" placeholder="Search..." />
                      <i className="ik ik-search" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-body d-flex align-items-center">
              <div className="container">
                <div className="apps-wrap">
                  <div className="app-item">
                    <a href="#"><i className="ik ik-bar-chart-2" /><span>Dashboard</span></a>
                  </div>
                  <div className="app-item">
                    <a href="#"><i className="ik ik-mail" /><span>Message</span></a>
                  </div>
                  <div className="app-item">
                    <a href="#"><i className="ik ik-users" /><span>Accounts</span></a>
                  </div>
                  <div className="app-item">
                    <a href="#"><i className="ik ik-shopping-cart" /><span>Sales</span></a>
                  </div>
                  <div className="app-item">
                    <a href="#"><i className="ik ik-briefcase" /><span>Purchase</span></a>
                  </div>
                  <div className="app-item">
                    <a href="#"><i className="ik ik-server" /><span>Menus</span></a>
                  </div>
                  <div className="app-item">
                    <a href="#"><i className="ik ik-clipboard" /><span>Pages</span></a>
                  </div>
                  <div className="app-item">
                    <a href="#"><i className="ik ik-message-square" /><span>Chats</span></a>
                  </div>
                  <div className="app-item">
                    <a href="#"><i className="ik ik-map-pin" /><span>Contacts</span></a>
                  </div>
                  <div className="app-item">
                    <a href="#"><i className="ik ik-box" /><span>Blocks</span></a>
                  </div>
                  <div className="app-item">
                    <a href="#"><i className="ik ik-calendar" /><span>Events</span></a>
                  </div>
                  <div className="app-item">
                    <a href="#"><i className="ik ik-bell" /><span>Notifications</span></a>
                  </div>
                  <div className="app-item">
                    <a href="#"><i className="ik ik-pie-chart" /><span>Reports</span></a>
                  </div>
                  <div className="app-item">
                    <a href="#"><i className="ik ik-layers" /><span>Tasks</span></a>
                  </div>
                  <div className="app-item">
                    <a href="#"><i className="ik ik-edit" /><span>Blogs</span></a>
                  </div>
                  <div className="app-item">
                    <a href="#"><i className="ik ik-settings" /><span>Settings</span></a>
                  </div>
                  <div className="app-item">
                    <a href="#"><i className="ik ik-more-horizontal" /><span>More</span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="exampleModalLong" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">{selectedUser?.firstName} {selectedUser?.lastName}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form role="form" method="POST" action>
                <input type="hidden" name="_token" defaultValue />

                <div className="form-group">
                  <label className="control-label">Kullanıcılar</label>
                  <div>
                    <table border={1} width='100%' cellPadding={'20px'}>
                      {
                        users.filter(e => e.parentAccount === selectedUser?._id).map((e, i) => {
                          return (
                            <tr>
                              <td style={{ padding: 10 }}>{e.firstName} {e.lastName}</td>
                              <td style={{ padding: 10 }}>  <a onClick={() => RemoteController.connectRemote(e._id)}><button type="button" className="btn btn-info btn-rounded">Panele Git</button></a></td>
                              <td style={{ padding: 10 }}>
                                {
                                  Object.keys(e.permissions)?.map(e =>
                                    <a onClick={() => RemoteController.connectRemote(e._id)}><button type="button" className="btn btn-danger btn-rounded">{PERMISSIONS[e]?.name}</button></a>
                                  )
                                }

                              </td>
                            </tr>
                          )
                        })
                      }
                    </table>
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label">Komisyon Oranı %</label>
                  <div>
                    <input type="number" className="form-control input" name="Komisyon Oranı" value={formData?.commissionRate} onChange={(e) => formHandler("commissionRate", e.target.value)} />
                  </div>
                </div>


              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Vazgeç</button>
              <button type="button" className="btn btn-primary" onClick={() => editAdminUser(selectedUser._id)}> Hesabı Güncelle</button>
            </div>
          </div>
        </div>
      </div>






      <div className="modal fade" id="createUserModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">{selectedUser?.firstName} {selectedUser?.lastName}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form className="forms-sample">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail3">Kullanıcı Adı</label>
                      <input type="text" className="form-control is-invalid" id="exampleInputText3" placeholder="Kullanıcı Adı" onChange={(e) => formHandler("username", e.target.value)} />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail3">Şifre</label>
                      <input type="password" className="form-control is-invalid" id="exampleInputNumber3" placeholder="Şifre" onChange={(e) => formHandler("password", e.target.value)} />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail3">Ad</label>
                      <input type="text" className="form-control is-invalid" id="exampleInputNumber3" placeholder="Ad" onChange={(e) => formHandler("firstName", e.target.value)} />
                    </div>
                  </div>


                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail3">Soyad</label>
                      <input type="text" className="form-control is-invalid" id="exampleInputEmail3" placeholder="Açık İsim" onChange={(e) => formHandler("lastName", e.target.value)} />
                    </div>
                  </div>

                  <div className="col-sm-6">
                  <label>Rolü</label>
                      <select className="form-control is-invalid" onChange={(e) => formHandler("type", e.target.value)}>
                      <option value="admin">Admin</option>
                        <option value="user">User</option>

                        <option value="super_admin">Super Admin</option>
                      </select>
                    </div>
                     
                </div>
              </form>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Vazgeç</button>
              <button type="button" className="btn btn-primary" onClick={() => createUser()}> Hesabı Kaydet</button>
            </div>
          </div>
        </div>
      </div>
    </>


  );
}

export default App;
