import { useState } from "react"
import "./General.css"
import axios from "axios"
import config from "../config.json"
import Cookies from "js-cookie";
import SwitchSelector from "react-switch-selector";
import { useEffect } from "react";

function App() {

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [gaCode, setGaCode] = useState("")
  const [mode, setMode] = useState("papara")

  const login = async () => {
    const result = await axios.post(config.apiUrl + "auth/login", { username, password, gaCode })
    console.log("Message", result.data)
    if (result.data.already_logged) {
      alert("Bu kullanıcı zaten giriş yapmış")
      return
    }
    if (result.data.status != "fail") {
      Cookies.set("token", result.data.token)
      localStorage.setItem("profile", JSON.stringify(result.data.profile))
      const getnotificationToken = await localStorage.getItem('notificationToken')
      if (getnotificationToken) {
        await axios.post(config.apiUrl + "users/set-notification", {
          token: result.data.token,
          notificationToken: getnotificationToken
        })
      }


      if (result.data.profile.type == 'super_admin') {
        window.location = '/super-admin'

      }
      else {
        window.location = '/'

      }

    }
    else {
      alert(result.data.message)
    }
  }

  useEffect(() => {
    onChange("papara")
  }, [])
  const onChange = (newValue) => {
    localStorage.setItem('mode', newValue || "papara")
    setMode(newValue)
  };

  const options = [
    {
      label: <span>Banka/Havale</span>,
      value: "banka",
      selectedBackgroundColor: "#0097e6",
    },
    {
      label: <span>Papara</span>,
      value: "papara",
      selectedBackgroundColor: "#0097e6",
    },
  ];


  return (
    <div>
      <title>Giriş | Admin Panel</title>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {/*===============================================================================================*/}
      <link rel="icon" type="image/png" href="#" />
      {/*===============================================================================================*/}
      <link rel="stylesheet" type="text/css" href="ui/vendor/bootstrap/css/bootstrap.min.css" />
      {/*===============================================================================================*/}
      <link rel="stylesheet" type="text/css" href="ui/fonts/font-awesome-4.7.0/css/font-awesome.min.css" />
      {/*===============================================================================================*/}
      <link rel="stylesheet" type="text/css" href="ui/fonts/iconic/css/material-design-iconic-font.min.css" />
      {/*===============================================================================================*/}
      <link rel="stylesheet" type="text/css" href="ui/vendor/animate/animate.css" />
      {/*===============================================================================================*/}
      <link rel="stylesheet" type="text/css" href="ui/vendor/css-hamburgers/hamburgers.min.css" />
      {/*===============================================================================================*/}
      <link rel="stylesheet" type="text/css" href="ui/vendor/animsition/css/animsition.min.css" />
      {/*===============================================================================================*/}
      <link rel="stylesheet" type="text/css" href="ui/vendor/select2/select2.min.css" />
      {/*===============================================================================================*/}
      <link rel="stylesheet" type="text/css" href="ui/vendor/daterangepicker/daterangepicker.css" />
      {/*===============================================================================================*/}
      <link rel="stylesheet" type="text/css" href="ui/css/util.css" />
      <link rel="stylesheet" type="text/css" href="ui/css/login.css" />
      {/*===============================================================================================*/}
      <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100">
            <form className="login100-form validate-form">
              <span className="login100-form-title p-b-26">
                Hoşgeldiniz
              </span>
              <span className="login100-form-title p-b-48">
              </span>
              <div className="wrap-input100 validate-input" data-validate="Kullanıcı Adı">
                <input className="input100" type="text" name="username" placeholder="Kullanıcı Adı" onChange={(e) => setUsername(e.target.value)} />
                <span className="focus-input100" />
              </div>
              <div className="wrap-input100 validate-input" data-validate="Enter password">
                <span className="btn-show-pass">
                  <i className="zmdi zmdi-eye" />
                </span>
                <input className="input100" type="password" name="pass" placeholder="Şifre" onChange={(e) => setPassword(e.target.value)} />
                <span className="focus-input100" />
              </div>
              <div className="wrap-input100 validate-input" data-validate="GA Kodu">
                <input className="input100" type="text" placeholder="GA Kodu" name="GA Kodu" onChange={(e) => setGaCode(e.target.value)} />
                <span className="focus-input100" />
              </div>
              <SwitchSelector
                onChange={onChange}
                options={options}
                initialSelectedIndex={1}

              />
              <button onClick={() => login()} type="button" className="btn btn-primary loginButton" style={{ padding: '5px', marginTop: 10 }}>
                Giriş Yap
              </button>

              {/*
      <div class="container-login100-form-btn">
                    
        <div class="wrap-login100-form-btn">
          
                        
              <button class="login100-form-btn">
                                
            Login
              </button>
                         
        </div>
               
      </div>
                */}
            </form>
          </div>
        </div>
      </div>

    </div>
  );
}

export default App;
