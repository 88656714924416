

import "./ExternalYatirim/css/style.css";
import DatePicker from "react-datepicker";
import "./ExternalYatirim/css/montserrat-font.css"
import "./ExternalYatirim/fonts/material-design-iconic-font/css/material-design-iconic-font.min.css"
import bgImage from "./ExternalYatirim/images/wizard-v10-bg.jpg"
import config from "../config.json";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios"
import Cookies from "js-cookie";
import { useEffect, useState } from 'react';
import $ from "jquery"
import moment from 'moment';
import Countdown from 'react-countdown';
import IbanInput from "../components/IbanInput";
import io from 'socket.io-client';
import bankAccounts from "../banks.json"

function userNameEncoder(name) {
  try {
    return name?.replace(/_/g, " ")
  }
  catch (e) {
    return name
  }
}

const urlParams = new URLSearchParams(window.location.search);
const userId = urlParams.get('userId');
const website = urlParams.get('website');
const nameSurname = userNameEncoder(urlParams.get('user_name_surname'));
const user_id = urlParams.get('user_id');
const callback = urlParams.get('callback');
const transaction_id = urlParams.get('transaction_id');
const redirect_url = urlParams.get('redirect_url');
const hash = urlParams.get('hash');
const amount = urlParams.get('amount');




function App({ fixed, papara=false }) {

  

  const LAST_STEP = 2;
  const [formData, setFormData] = useState({})
  const [step, setStep] = useState(2)
  const [socket , setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(socket?.connected);
  const [lastPong, setLastPong] = useState(null);
  const [datenow, setDatenow] = useState(Date.now());
  useEffect(()=>{
    const socketC =  io(config.serverUrl, {
      query: {
        userId,
        website,
        nameSurname,
        user_id,
        callback,
        transaction_id,
        hash,
        amount,
        type: "deposit"
      }
    
    });
    setSocket(socketC)
  },[])


  const [ip,setIP] = useState('');
    
  //creating function to load ip address from the API
  const getData = async()=>{
      const res = await axios.get('https://geolocation-db.com/json/')
      setIP(res.data.IPv4)
  }
  
  useEffect(()=>{
      //passing getData method to the lifecycle method
      getData()
  },[])


  useEffect(() => {
    if (fixed && socket) {


      socket.on('connect', () => {
        setIsConnected(true);
      });

      socket.on('disconnect', () => {
        setIsConnected(false);
        document.write("Sunucu bağlantısı kesildi")
      });

      socket.on('pong', () => {
        setLastPong(new Date().toISOString());
      });

      return () => {
        socket.off('connect');
        socket.off('disconnect');
        socket.off('pong');
      };
    }
  }, [socket]);

  const handleInput = (name, value) => {
    setFormData({
      ...formData,
      [name]: value
    })

  }




  useEffect(() => {
    if (fixed) {
      handleInput("amount", amount)
    }

  }, [])


  const makeWithdrawRequest = async () => {
    if(!formData?.accountNumber || !nameSurname || !formData.amount){
      console.log(!formData?.accountNumber, !nameSurname, !formData.amount)
      return alert("Lütfen tüm alanları doldurunuz")
    }

    try {
      const req = await axios.post(config.apiUrl + "transactions/makewithdrawrequest", {
        transaction_id: transaction_id,
        hash: hash,
        userId: userId,
        amount: formData?.amount || 0,
        bankAccount: { bankName: papara ? "Papara": formData?.bank, accountNumber: formData?.accountNumber, nameSurname: formData?.firstName + " " + formData?.lastName, iban: formData?.iban },
        nameSurname: nameSurname,
        user_id: user_id,
        webSite: website,
        transactionTime: moment().format('YYYY-MM-DD HH:mm:ss'),
        callback: callback,
        ipaddress: ip,
        papara : papara
      })
      // alert("ÇEKİM TALEBİNİZ GÖNDERİLDİ. TEŞEKKÜRLER")
      // // window.location.href = callback;
      // window.location.href = "about:blank"
      if(redirect_url){
        window.location.href = redirect_url
      }
      else{
        document.write("ÇEKİM TALEBİNİZ GÖNDERİLDİ. TEŞEKKÜRLER. SAYFAYI KAPATABİLİRSİNİZ")
      }
     
    }
    catch (e) {
      alert('HATA OLDU')
      console.log(e)
    }

  }

  const handleNextStep = async () => {
    if (step == LAST_STEP) {
      if ((!papara &&!formData?.iban) || !formData?.amount) {
        alert('Lütfen tüm alanları doldurunuz')
      }
      else {
        await makeWithdrawRequest();
      }
    }
    else if (step == 1) {
      if (!formData?.firstName || !formData?.lastName) {
        alert('Boş alanları doldurun')
      }
      else setStep(prev => prev + 1)
    }
    else {
      setStep(prev => prev + 1)
    }
  }
  const handlePreviousStep = () => {
    if (step != 1) {
      setStep(prev => prev - 1)
    }

  }
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      // alert('bitti')
      window.location.href = "about:blank";
    } else {
      // Render a countdown
      return <span>{minutes < 10 ? "0" + minutes : minutes}:{seconds < 10 ? "0" + seconds : seconds}</span>;
    }
  };
  return (
    <>
    <head>
        <meta charset="UTF-8"/>
        <meta http-equiv="x-ua-compatible" content="ie=edge"/>
        <title>Manuel Yatırım | Admin Panel</title>
        <meta name="description" content=""/>
        <meta name="keywords" content=""/>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        
        <link rel="icon" href="#" type="image/x-icon" />

        <link href="https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800" rel="stylesheet"/>
        
        
        <link rel="stylesheet" href="plugins/bootstrap/dist/css/bootstrap.min.css" />
        <link rel="stylesheet" href="plugins/fontawesome-free/css/all.min.css" />
        <link rel="stylesheet" href="plugins/icon-kit/dist/css/iconkit.min.css" />


        <link rel="stylesheet" href="dist/css/theme.min.css" />

<link href="pages/assets/css/bootstrap.min.css" rel="stylesheet" />

<link href="pages/assets/css/paparaStyle.css" rel="stylesheet" />



<link href="https://netdna.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.css" rel="stylesheet"/>
<link href='https://fonts.googleapis.com/css?family=Muli:400,300' rel='stylesheet' type='text/css'/>
<link href="pages/assets/css/themify-icons.css" rel="stylesheet"/>


<script src="src/js/vendor/modernizr-2.8.3.min.js"></script>
<script src="https://kit.fontawesome.com/a076d05399.js" crossorigin="anonymous"></script>

<style>
  
</style>


      
    </head>
    <div class="wrapper" style={{
          background: '#F6F7FB',
          width: '100vw',
          height: '100vh',
          display: 'block',
    }}>
           
    <div class="xd">
                        <div class="dx">
                            <div class="col-sm-8 col-sm-offset-2">
                                <div class="wizard-container">
                                    <div class="card wizard-card" data-color="orange" id="wizard">
                                    <form action="" method="">
            
                                            <div class="wizard-header">
                                                <h3 class="wizard-title">Papara Ödeme</h3>
                                                <p class="category">Bu bölümden papara ile ödeme yapabilirsiniz.</p>
                                            </div>
                                          {true&&  <>
                                            <div class="wizard-navigation">
                                                <div class="progress-with-circle">
                                                    <div class="progress-bar" role="progressbar" aria-valuenow="1" aria-valuemin="1" aria-valuemax="4" style={{width: '15%'}}></div>
                                                </div>
                                                <ul>
                                                    <li>
                                                        <a href="#description" data-toggle="tab">
                                                            <div class="icon-circle">
                                                                <i class="icons ik ik-menu"></i>
                                                            </div>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>


                                            <div class="tab-content">
                                               
                                                
                                               
                                                <div class="tab-pane" id="description">
                                                    <p class="alert alert-warning ManuelAuto-alert">
                                                        Lütfen altta size verilen Papara numaramıza,
                                                        transferi belirtilen sürede gerçekleştiriniz.
                                                        Ardından <b>'Ödemeyi Yaptım'</b> tuşuna basınız. Karekoda tıklayarak
                                                        veya mobil cihaz ile tarayarak ödeme sayfasına gidebilirsiniz.
                                                    </p>
                                              
                                                    <div class="investment-input" style={{margin:20}}>
                                                            <label for="fname">Çekim Tutarı: </label>
                                                            <input type="number" placeholder="TL - ₺" id="ytutari" name="ytutari" disabled={fixed} value={formData.amount} placeholder="Yatırım tutarı giriniz" onChange={(e) => handleInput("amount", e?.target.value)} />
                                                        </div>

                                                        <div class="investment-input" style={{margin:20}}>
                                                            <label for="fname">Papara Hesap Numarası: </label>
                                                            <input type="text" id="last-name" name="last-name" required onChange={(e) => handleInput("accountNumber", e?.target.value)}/>
                                                        </div>

                                                        <div class="investment-input" style={{margin:20}}>
                                                            <label for="fname">Ad soyad: </label>
                                                            <input type="text" placeholder="TL - ₺" id="ytutari" name="ytutari" disabled={fixed} value={nameSurname} disabled />
                                                        </div>


                                                </div>
                                            </div>
                                            
                                            <div class="wizard-footer">
                                                <div class="pull-right">
                                                    <input type='button' class='nextButton btn btn-next btn-fill btn-wd' name='next' value='Next' />
                                                    <button type="button" class=" nextButton btn btn-finish btn-fill btn-wd" onClick={async()=>{
                                                      await makeWithdrawRequest();
                                                    }}>Çekim İsteği Gönder</button>
                                                </div>
            
                                                <div class="pull-left">
                                                    <input type='button' class='prevButton btn btn-previous btn-default btn-wd' name='previous' value='Previous' />
                                                </div>
                                                <div class="clearfix"></div>
                                            </div>
                                            </>}
                                        </form>
                                    </div>
                                </div> 
                            </div>
                        </div> 
                    </div> 
            
        </div>
        </>

  );
}

export default App;
