
import { useState, useEffect } from "react"
import axios from "axios"
import config from "../config.json"
import Cookies from "js-cookie";
import $ from 'jquery';
import DataTable from 'datatables.net';
import moment from 'moment';
import * as RemoteController from "../remoteControl"
import Select from 'react-select'

export default function App() {

    const token = RemoteController.getToken()
    const [avaibleAccounts, setAvaibleAccounts] = useState([])

    const [transcationMovementInfo, setTranscationMovementInfo] = useState(null)
    const [selectedUserId, setSelectedUserId] = useState(null)
    const [bankAccouts, setBankAccounts] = useState([])
    const [selectedBankId, setSelectedBankId] = useState("")
    const [pools, setPools] = useState([])
    const [selectedTransferPool, setSelectedTransferPool] = useState("")



    const getBankAccounts = async () => {

        const req = await axios.get(config.apiUrl + "bankaccounts/getbyuser", {
            params: {
                token: Cookies.get('token'),
                userId: selectedUserId
            }
        })

        setBankAccounts(req.data)

    }
    useEffect(() => {
        if (selectedUserId != null) {
            getBankAccounts()
        }
    }, [selectedUserId])


    const [formData, setFormData] = useState({
        website: "",
        amount: "",
        transactionId: "",
        userIdInWebsite: "",
        nameSurname: "",
        bankAccountId: "",
        transactionTime: ""
    })
    const queryParams = new URLSearchParams(window.location.search);
    const _id = queryParams.get('_id');


    const transferTransaction = () => {
        const req = axios.post(config.apiUrl + "pool/transfer", {

            token: Cookies.get('token'),
            transactionId: formData._id,
            poolId: selectedTransferPool,

        }).then(() => window.location.href = '/').catch(e => alert('hata' + e))

    }

    const getTranscationMovementInfo = async () => {
        const req = await axios.get(config.apiUrl + "transactions/transcationmovementinfo", {
            params: {
                token: Cookies.get('token'),
                _id: _id
            }
        })
        setTranscationMovementInfo(req.data)

    }

    const getPoolAccounts = async () => {
        const req = await axios.get(config.apiUrl + "pool", {
            params: {
                token: Cookies.get('token'),
            }
        })
        setPools(req.data)

    }


    const getTransactionInformation = async () => {

        const req = await axios.get(config.apiUrl + "transactions/getone", {
            params: {
                token: Cookies.get('token'),
                _id: _id
            }
        })
        setFormData(req.data)

    }




    const formHandler = (inputName, value, number = false) => {
        setFormData(prev => {
            const newDataForm = new Object({ ...formData })
            if (number)
                newDataForm[inputName] = Number(value)
            else
                newDataForm[inputName] = value

            return newDataForm
        }

        )
    }

    const editTransaction = () => {
        axios.post(config.apiUrl + "transactions/edittransaction", {
            token: Cookies.get('token'),
            ...formData,
        }).then(() => { alert("Başarılı bir şekilde eklendi"); window.location = '/cekimislemleri' }).catch(e => alert("Ekleme sırasında hata" + e))
    }
    const getAvaibleAccounts = async () => {
        const req = await axios.get(config.apiUrl + "bankaccounts/getavaiblebankaccounts", {
            params: {
                token: token
            }
        })

        setAvaibleAccounts(req.data)
    }

    useEffect(() => {
        getTranscationMovementInfo()
        getTransactionInformation()
        getAvaibleAccounts()
        getPoolAccounts()
    }, [])

    const removeTransaction = async (_id) => {
        const req = await axios.post(config.apiUrl + "transactions/remove", {
            token: Cookies.get('token'),
            _id: _id,

        })
        window.location.href = '/'

    }
    const validateRejectTransaction = async (status, _id, type) => {
        const req = await axios.post(config.apiUrl + "transactions/verifyrejectdeposit", {
            token: token,
            _id: _id,
            status: status ? 1 : 2,
            type: type

        })
        window.location.href = '/'

    }


    return (
        <div className="main-content">
            <div className="container-fluid">
                <div className="page-header">
                    <div className="row align-items-end">
                        <div className="col-lg-4">
                            <nav className="breadcrumb-container" aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="../index.html"><i className="ik ik-home" /></a>
                                    </li>

                                    <li className="breadcrumb-item active" aria-current="page">İşlem Düzenle</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header"><h3>İşlem Düzenle</h3></div>
                            <div className="card-body">
                                <form className="forms-sample">
                                    <div className="row">

                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail3">Kullanıcı Site ID</label>
                                                <input type="number" className="form-control is-invalid" id="exampleInputNumber3" placeholder="Yatırım yapan kullanıcının sitede ki ID'si" value={formData.userIdInWebsite} onChange={(e) => formHandler("userIdInWebsite", e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputText3">Site</label>
                                                <select className="form-control is-invalid" id="exampleInputEmail3" value={formData.website} onChange={(e) => formHandler("website", e.target.value)} >
                                                    <option value={0}>Lütfen Siteyi Seçin</option>
                                                    <option value={1}>*</option>
                                                    <option value={2}>**</option>
                                                    <option value={3}>***</option>
                                                    <option value={4}>****</option>
                                                    <option value={5}>*****</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail3">Açık İsim</label>
                                                <input type="text" className="form-control is-invalid" id="exampleInputEmail3" placeholder="Açık İsim" value={formData.nameSurname} onChange={(e) => formHandler("nameSurname", e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail3">Miktar</label>
                                                <input type="number" className="form-control is-invalid" id="exampleInputEmail3" placeholder="Miktar" value={formData.amount} onChange={(e) => formHandler("amount", e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail3">Yatırımın Yapıldığı Hesap</label>
                                                <select className="form-control is-invalid" id="exampleInputEmail3" value={formData.bankAccountId} onChange={(e) => formHandler("bankAccountId", e.target.value)} >
                                                    <option value={""}>Lütfen yatırımın yapıldığı hesabı seçin</option>
                                                    {avaibleAccounts.map(account => <option value={account._id}>{account.nameSurname} -  {account.bankName}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail3">İşlem ID</label>
                                                <input type="text" className="form-control is-invalid" id="exampleInputEmail3" placeholder="Site tarafındaki işlem ID'si" value={formData.transactionId} onChange={(e) => formHandler("transactionId", e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail3">Yatırım Saati</label><br></br>
                                                <label>{moment(formData.transactionTime).format('YYYY-MM-DD HH:mm')}</label>
                                                <input className="form-control is-invalid" type="datetime-local" onChange={(e) => formHandler("transactionTime", e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <button type="submit" className="btn btn-primary mr-2" onClick={() => editTransaction()}>Submit</button>
                                <button className="btn btn-light">Cancel</button>

                            </div>
                        </div>
                    </div>

                    {pools && formData.status === 0 && <div class="col-md-12">
                        <div class="card">
                            <div class="card-header"><h3>İşlem Yönlendir</h3></div>
                            <div class="card-body">
                                <div class="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail3">Mevcut Havuz</label>

                                            <input type="text" className="form-control is-invalid" id="exampleInputNumber3"
                                                value={"Grup " + (pools.findIndex(e => e._id == formData?.pool) + 1)}
                                                disabled

                                            // onChange={(e) => formHandler("userIdInWebsite", e.target.value)} 

                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail3">Mevcut Havuz</label>

                                            <Select options={
                                                pools.filter(e => e._id !== formData?.pool).map((e, i) => ({ label: "Grup " + (i + 1), value: e }))
                                            }
                                                onChange={(e) => setSelectedTransferPool(e.value)}
                                            />


                                        </div>
                                    </div>

                                </div>

                                <h3>Transfer Edilecek Hesap</h3>
                                <br></br>


                                <button type="submit" className="btn btn-primary mr-2" onClick={() => transferTransaction()}>Transfer</button>



                            </div>
                        </div>
                    </div>}
                    <div class="card">
                        <div class="card-header"><h3>Diğer İşlemler</h3></div>
                        <div class="card-body">

                            {
                                (formData.status == 1 || formData.status == 0) ?
                                    <button type="submit" className="btn btn-danger mr-2" onClick={() => validateRejectTransaction(false, formData._id)}>Reddet</button> :
                                    <button type="submit" className="btn btn-success mr-2" onClick={() => validateRejectTransaction(true, formData._id)}>Onayla</button>
                            }


                            <button type="submit" className="btn btn-danger mr-2" onClick={() => removeTransaction(formData._id)}>Kaldır</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}