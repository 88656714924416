

import { useState, useEffect } from "react"
import axios from "axios"
import config from "../config.json"
import Cookies from "js-cookie";
import $ from 'jquery';
import DataTable from 'datatables.net';
import moment from 'moment';
import * as RemoteController from "../remoteControl"
import Select from 'react-select'
function formatMoney(n) {
  try {
    if (!n) return n
    const x = parseFloat(n).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1.').replace(/\.(\d+)$/, ',$1');
    return x
    // return isNaN(x) ? 0 : x
  }
  catch (e) {
    return n
  }
}


function App() {
  const token = RemoteController.getToken()
  const [formData, setFormData] = useState({
    website: "",
    amount: "",
    transactionId: "",
    userIdInWebsite: "",
    nameSurname: "",
    bankAccountId: "",
    transactionTime: "",
  })
  const [validationCode, setValidationCode] = useState("")
  const [avaibleAccounts, setAvaibleAccounts] = useState([]);
  const [selectedBankAccount, setSelectedBankAccount] = useState(null)
  const [selectedBankAccountObj, setSelectedBankAccountObj] = useState(null)
  const getProfileInformation = JSON.parse(localStorage.getItem('profile'))
  const queryParams = new URLSearchParams(window.location.search);
  const _id = queryParams.get('_id');

  const getTransactionInformation = async () => {

    const req = await axios.get(config.apiUrl + "transactions/getone", {
      params: {
        token: Cookies.get('token'),
        _id: _id
      }
    })
    if (req.data.validationCode) {
      setValidationCode(req.data.validationCode || "")
    }
    setFormData(req.data)

  }

  const removeTransaction = async (_id) => {
    const req = await axios.post(config.apiUrl + "transactions/remove", {
      token: Cookies.get('token'),
      _id: _id,

    })
    window.location.href = '/cekimislemleri'

  }



  const updateValidationCode = async (_id) => {


    const req = await axios.post(config.apiUrl + "transactions/updatevalidationcode", {
      token: Cookies.get('token'),
      _id: _id,
      validationCode: validationCode || ""

    })
    window.location.href = '/info?_id=' + _id

  }


  const validateRejectTransaction = async (status, _id) => {
    if (formData?.amount > selectedBankAccountObj?.balance && status == true) {
      if (window.confirm("Hesapta yeterli bakiye yok, yine de onaylamak istiyor musunuz?") != true) {
        return
      }
    }

    const req = await axios.post(config.apiUrl + "transactions/verifyrejectdeposit", {
      token: Cookies.get('token'),
      _id: _id,
      status: status ? 1 : 2,
      selectedBankAccount: selectedBankAccount.value,
      type: "withdraw",
      validationCode: validationCode

    })
    window.location.href = '/info?_id=' + _id

  }
  const getAvaibleAccounts = async () => {
    const req = await axios.get(config.apiUrl + "bankaccounts/getavaiblebankaccounts", {
      params: {
        token: token,
        allBanks: true
      }
    })

    setAvaibleAccounts(req?.data)
    if (req?.data?.length > 0)
      setSelectedBankAccount({ label: req.data[0]?.bankName + " - " + req.data[0]?.nameSurname, value: req.data[0]?._id })
    setSelectedBankAccountObj(req.data[0])

  }

  useEffect(() => {
    getTransactionInformation()
    getAvaibleAccounts();
  }, [])

  return (

    <div className="main-content">
      <div className="container-fluid">
        <div className="page-header">
          <div className="row align-items-end">
            <div className="col-lg-8">
              <div className="page-header-title">
                <i className="ik ik-edit bg-blue" />
                <div className="d-inline">
                  <h5>Çekim İsteği Detayları</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <nav className="breadcrumb-container" aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="../index.html"><i className="ik ik-home" /></a>
                  </li>
                  <li className="breadcrumb-item"><a href="cekimislemleri.html">Çekim İşlemleri</a></li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-md-6">
            <div className="card" style={{ minHeight: '300px' }}>
              <div className="card-header"><h3>Çekim Detayları</h3></div>
              <div className="card-body">
               <div className="forms-sample">
                  <div className="form-group row">
                    <label htmlFor="exampleInputUsername2" className="col-sm-3 col-form-label">Site:</label>
                    <div className="col-sm-9">
                      <label>{formData?.website}</label>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="exampleInputEmail2" className="col-sm-3 col-form-label">Anlık Bakiye:</label>
                    <div className="col-sm-9">
                      <label style={{ fontWeight: 'bold' }}>11.116,34</label>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="exampleInputMobile" className="col-sm-3 col-form-label">Kullanıcı ID:</label>
                    <div className="col-sm-9">
                      <label>{formData?.userIdInWebsite}</label>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="exampleInputPassword2" className="col-sm-3 col-form-label">Kullanıcı Adı:</label>
                    <div className="col-sm-9">
                      <label></label>
                    </div>
                  </div>
               </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card" style={{ minHeight: '300px' }}>
              <div className="card-header"><h3>Çekim Dekont Bilgisi</h3></div>
              <div className="card-body">
               <div className="forms-sample">
                  <div className="form-group row">
                    <label htmlFor="exampleInputPassword2" className="col-sm-3 col-form-label">Mevcut Dekont Linki:</label>
                    <div className="col-sm-9">
                       <label>https://prnt.sc/1xq7p4l<br />Lütfen çekim dekontunun ekran görüntüsü linkini aşağıdaki kutuya yapıştırıp ekle butonuna basın. Bu işlem tekrarlanabilir niteliktedir.</label> 
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="exampleInputPassword2" className="col-sm-3 col-form-label" />
                    <div className="col-lg-9">
                      <input style={{ height: '100px' }} type="text" className="form-control" id="text" placeholder="Dekont Görsel Linki" />
                    </div>
                  </div>
                  <button type="submit" className="btn btn-primary mr-4">EKLE</button>
               </div >
              </div >
            </div >
          </div >
        </div > */}




        < div className="row" >
          <div className="col-md-6">
            <div className="card" style={{ minHeight: '300px' }}>
              <div className="card-header"><h3>Banka Hesap Bilgileri</h3></div>
              <div className="card-body">
                <div className="forms-sample">
                  <div className="form-group row">
                    <label htmlFor="exampleInputUsername2" className="col-sm-3 col-form-label">Site:</label>
                    <div className="col-sm-9">
                      <label>{formData?.website}</label>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="exampleInputUsername2" className="col-sm-3 col-form-label">Site Kullanıcı:</label>
                    <div className="col-sm-9">
                      <label>{formData?.userIdInWebsite}</label>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="exampleInputUsername2" className="col-sm-3 col-form-label">Kullanıcı Ad Soyad:</label>
                    <div className="col-sm-9">
                      <label>{formData?.nameSurname}</label>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="exampleInputEmail2" className="col-sm-3 col-form-label">Gönderilecek Banka:</label>
                    <div className="col-sm-9">
                      <label>{formData?.bankAccount?.bankName}</label>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="exampleInputMobile" className="col-sm-3 col-form-label">Gönderilecek Hesap Sahibi İsmi:</label>
                    <div className="col-sm-9">
                      <label style={{fontSize:20, fontWeight:'bold'}}>{formData?.bankAccount?.nameSurname}</label>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="exampleInputPassword2" className="col-sm-3 col-form-label">Gönderilecek Kullanıcı Hesap No:</label>
                    <div className="col-sm-9">
                      <label>{formData?.bankAccount?.accountId}</label>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="exampleInputPassword2" className="col-sm-3 col-form-label">Gönderilecek IBAN:</label>
                    <div className="col-sm-9">
                      <label style={{fontSize:20, fontWeight:'bold'}}>{formData?.bankAccount?.iban}</label>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="exampleInputPassword2" className="col-sm-3 col-form-label">Gönderilecek Miktar:</label>
                    <div className="col-sm-9">
                      <label style={{fontSize:20, fontWeight:'bold'}}>{formatMoney(formData?.amount)} TL</label>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="exampleInputPassword2" className="col-sm-3 col-form-label">İşlem Tarihi:</label>
                    <div className="col-sm-9">
                      <label>{moment(formData?.transactionTime).format('DD/MM/YYYY HH:mm:ss')}</label>
                    </div>
                  </div>
                  {/* <div className="form-group row">
                    <label htmlFor="exampleInputPassword2" className="col-sm-3 col-form-label">Açıklama:</label>
                    <div className="col-sm-9">
                      <label>garanti bankası</label>
                    </div>
                  </div> */}
                  <div className="form-group row">
                    <label htmlFor="exampleInputPassword2" className="col-sm-3 col-form-label">Durum:</label>
                    <div className="col-sm-9">
                      <label style={{ fontWeight: 'bold', color: formData?.status == 0 ? 'yellow' : formData?.status == 1 ? 'green' : 'red' }}>{formData?.status == 0 ? "Onay Bekliyor" : formData?.status == 1 ? "Onaylandı" : "Reddedildi"}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div >
          </div >
          <div className="col-md-6">
            <div className="card">
              <div className="card-header"><h3>Üyenin Son 5 İşlemi</h3></div>
              <div className="card-body">
                <table id="example" className="table table-striped table-bordered" style={{ width: '50px', fontSize: '12px' }}>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Position</th>
                      <th>Office</th>
                      <th>Age</th>
                      <th>Start date</th>
                      <th>Salary</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Tiger Nixon</td>
                      <td>System Architect</td>
                      <td>Edinburgh</td>
                      <td>61</td>
                      <td>2011/04/25</td>
                      <td>$320,800</td>
                    </tr>
                    <tr>
                      <td>Tiger Nixon</td>
                      <td>System Architect</td>
                      <td>Edinburgh</td>
                      <td>61</td>
                      <td>2011/04/25</td>
                      <td>$320,800</td>
                    </tr>
                    <tr>
                      <td>Tiger Nixon</td>
                      <td>System Architect</td>
                      <td>Edinburgh</td>
                      <td>61</td>
                      <td>2011/04/25</td>
                      <td>$320,800</td>
                    </tr>
                    <tr>
                      <td>Tiger Nixon</td>
                      <td>System Architect</td>
                      <td>Edinburgh</td>
                      <td>61</td>
                      <td>2011/04/25</td>
                      <td>$320,800</td>
                    </tr>
                    <tr>
                      <td>Tiger Nixon</td>
                      <td>System Architect</td>
                      <td>Edinburgh</td>
                      <td>61</td>
                      <td>2011/04/25</td>
                      <td>$320,800</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th>Name</th>
                      <th>Position</th>
                      <th>Office</th>
                      <th>Age</th>
                      <th>Start date</th>
                      <th>Salary</th>
                    </tr>
                  </tfoot>
                </table>
                {/*buraya data table gelecek*/}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card" style={{ minHeight: '400px' }}>
              <div className="card-header"><h3>Operasyon Hesap Bilgileri</h3></div>
              <div className="card-body">
                <div className="forms-sample">
                  <div className="form-group row">
                    <label htmlFor="exampleInputUsername2" className="col-sm-3 col-form-label">Banka Hesabı Seçin</label>
                    <div className="col-sm-9">
                      {!formData?.selectedBankAccount && selectedBankAccount && <Select

                        placeholder="Banka Hesabı Seçin"
                        value={selectedBankAccount}
                        options={avaibleAccounts?.map(e => ({ value: e?._id, label: e?.bankName + " - " + e?.nameSurname }))}
                        onChange={(eq) => { setSelectedBankAccount(eq); setSelectedBankAccountObj(avaibleAccounts?.find(c => c._id == eq.value)); }}
                      />}
                      {formData?.selectedBankAccount && <p>{formData?.selectedBankAccount?.bankName} - {formData?.selectedBankAccount?.nameSurname}</p>}
                      {!selectedBankAccount && <p>Aktif banka hesabı bulunamadı</p>}
                    </div>
                  </div>



                  <div className="form-group row">
                    <label htmlFor="exampleInputUsername2" className="col-sm-3 col-form-label">Gönderilecek Hesap Sahibi İsmi:</label>
                    <div className="col-sm-9">
                      <label>{selectedBankAccountObj?.nameSurname}</label>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="exampleInputEmail2" className="col-sm-3 col-form-label">Gönderilecek Hesap Numarası:</label>
                    <div className="col-sm-9">
                      <label>{selectedBankAccountObj?.accountNumber}</label>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="exampleInputMobile" className="col-sm-3 col-form-label">Gönderilecek Hesap Şube Numarası:</label>
                    <div className="col-sm-9">
                      <label>{selectedBankAccountObj?.branchCode}</label>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="exampleInputPassword2" className="col-sm-3 col-form-label">Gönderilecek Hesap IBAN:</label>
                    <div className="col-sm-9">
                      <label>{selectedBankAccountObj?.iban}</label>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="exampleInputConfirmPassword2" className="col-sm-3 col-form-label">Hesaptaki Miktar</label>
                    <div className="col-sm-9">
                      <label>{formatMoney(selectedBankAccountObj?.balance)} TL</label>
                    </div>
                  </div>
                 {getProfileInformation.type != 'website' && <div className="form-group row">
                    <label htmlFor="exampleInputConfirmPassword2" className="col-sm-3 col-form-label">Onay Notu</label>
                    <div className="col-sm-9">
                      <input type="text" className="form-control" id="text" placeholder="Onay Notu" value={validationCode} onChange={(e) => setValidationCode(e.target.value)} />
                    </div>
                  </div>}

                  {getProfileInformation.type == 'website' && <div className="form-group row">
                    <label htmlFor="exampleInputConfirmPassword2" className="col-sm-3 col-form-label">Onay Notu</label>
                    <div className="col-sm-9">
                    {validationCode}
                    </div>
                  </div>}

                  {
                    (formData.status == 0) &&
                    <>
                      <button type="submit" className="btn btn-danger mr-2" onClick={() => validateRejectTransaction(false, formData._id)}>Reddet</button>
                      <button type="submit" className="btn btn-success mr-2" onClick={() => validateRejectTransaction(true, formData._id)}>Onayla</button>
                      {/* <button type="submit" className="btn btn-light" onClick={() => removeTransaction(formData._id)}>İptal Et</button> */}
                    </>
                  }
                  {
                    (formData.status != 0 && getProfileInformation.type != 'website') &&
                    <>
                      <button type="submit" className="btn btn-danger mr-2" onClick={() => updateValidationCode(formData._id)}>Değiştir</button>
                    </>
                  }




                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    </div >
  );
}

export default App;
