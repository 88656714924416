
import axios from "axios"
import Cookies from "js-cookie";
import config from "./config.json"

const isControlling = () => {
    return localStorage.getItem('remoteToken') != null
}

const getToken = () => {
    if (isControlling()) {
        return localStorage.getItem('remoteToken')
    }
    else {
        return Cookies.get('token')
    }
}




const connectRemote = async (userId) => {
    const req = await axios.get(config.apiUrl + "users/connectremote", {
        params: {
            userId,
            token: Cookies.get('token')
        }
    })

    const token = req.data.token
    const profile = req.data.profile
    localStorage.setItem('remoteToken', token)
    localStorage.setItem('remoteProfile', JSON.stringify(profile))
    window.location = '/'
    return true
}

const logOutRemote = (isSuperAdmin) => {
    localStorage.removeItem('remoteToken')
    localStorage.removeItem('remoteProfile')
    window.location = isSuperAdmin ? '/super-admin' : '/kullanicilar'
    return true
}
export {
    isControlling,
    getToken,
    connectRemote,
    logOutRemote
}