import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Cookies from "js-cookie";
import Home from "./pages/Home"
import Mutabakat from "./pages/Mutabakat"
import CekimIslemleri from "./pages/CekimIslemleri"
import BankaHesaplari from "./pages/BankaHesaplari"
import Info from "./pages/Info"
import Login from "./pages/Login"
import IslemListesi from "./pages/IslemListesi";
import ForgetPassword from "./pages/ForgetPassword"
import NotFoundPage from "./pages/404"
import ManuelYatirim from "./pages/ManuelYatirim"
import KullaniciAc from "./pages/KullaniciAc"
import Kullanicilar from "./pages/Kullanicilar"
import Havuzlar from "./pages/Havuzlar"
import EditBank from "./pages/EditBank"
import * as RemoteController from "./remoteControl"
import AdminTransactionEdit from "./pages/AdminTransactionEdit"
// import ExternalYatirim from "./pages/ExternalYatirim"
import ExternalCommon from "./pages/ExternalCommon"
import DepositPage from "./pages/DepositPage"
import Skype from "./pages/Skype"

// import ExternalCekim from "./pages/ExternalCekim"
// import ExternalYatirimPapara from "./pages/ExternalYatirimPapara"
import { PERMISSIONS, checkPermission } from "./consts/Permissions"
import SuperAdmin from "./pages/SuperAdmin"
import SuperAdminRapor from "./pages/SuperAdminRapor"
import ExternalCekimPapara from "./pages/ExternalCekimPapara"
import Players from "./pages/Players"
import axios from "axios"
import config from "./config.json"
import { isMobile } from 'react-device-detect';


// (B) REGISTER SERVICE WORKER
async function regWorker() {
  // (B1) YOUR PUBLIC KEY - CHANGE TO YOUR OWN!
  const publicKey = "BP57YdwYeBDMvFrF9MjUKKasFZaoFSAo6iTKafNoUHxWO89bfWp3AMWAOivWe4UJGPCnEHIj7Q29kQcd9rCg-Tk";
  console.log("regWorker")
  // (B2) REGISTER SERVICE WORKER
  navigator.serviceWorker.register("sw.js", { scope: "/" });

  // (B3) SUBSCRIBE TO PUSH SERVER
  navigator.serviceWorker.ready
    .then(reg => {
      reg.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: publicKey
      }).then(
        // (B3-1) OK - TEST PUSH NOTIFICATION
        sub => {
          console.log("sub", sub)
          localStorage.setItem('notificationToken', JSON.stringify(sub))
        },

        // (B3-2) ERROR!
        err => console.error(err)
      );
    });
}


function App() {
  const getProfileInformation = JSON.parse(localStorage.getItem('profile'))
  const [mode, setMode] = React.useState('')

  React.useEffect(() => {
    // async
    const getMode = async () => {
      const mode = await localStorage.getItem('mode')
      if (mode) {
        setMode(mode)
      }
      else {
        setMode('banka')
        await localStorage.setItem('mode', 'banka')
      }

    }
    getMode()
  }, [])

  // React.useEffect(() => {
  //   const getnotificationToken = localStorage.getItem('notificationToken')
  //   if (getProfileInformation) {
  //     console.log("getProfileInformation",getProfileInformation)
  //     axios.post(config.apiUrl + "users/set-notification", {
  //       notificationToken: getnotificationToken
  //     }).then(() => { }).catch(e => console.log("Notification token set edilemedi"))

  //   }
  // }, [getProfileInformation])



  useEffect(() => {
    if (!isMobile && Cookies.get("token")) {


      // (A1) ASK FOR PERMISSION
      if (Notification.permission === "default") {
        Notification.requestPermission().then(perm => {
          if (Notification.permission === "granted") {
            regWorker().catch(err => console.error(err));
          } else {
            // alert("Please allow notifications.");
          }
        });
      }

      // (A2) GRANTED
      else if (Notification.permission === "granted") {
        regWorker().catch(err => console.error(err));
      }

      // (A3) DENIED
      else {
        // alert("Please allow notifications.");
      }
    }
  }, [])

  if (!Cookies.get("token")) {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/logloglog" component={Login} exact />
          {/* <Route path="/forgetpassword" component={ForgetPassword} exact /> */}
          {/* <Route path="/external-yatirim" component={ExternalYatirim} exact />
          <Route path="/external-cekim" component={ExternalCekim} exact />
          <Route path="/external-yatirim-fixed-amount" render={() => <ExternalYatirim fixed={true} />} exact />
          <Route path="/external-cekim-fixed-amount" render={() => <ExternalCekim fixed={true} />} exact />
          <Route path="/external-yatirim-papara" render={() => <ExternalYatirimPapara papara={true} />} exact />
          <Route path="/external-cekim-papara" render={() => <ExternalCekimPapara papara={true} />} exact /> */}
          <Route path="/external-common" component={ExternalCommon} exact />
          <Route path="/deposit" component={DepositPage} exact />
          {/* <Route path="/external-common-papara" render={() => <ExternalCommon paparaPage={true} />} exact /> */}
          <Route component={NotFoundPage} exact />
        </Switch>
      </BrowserRouter>

    )

  }
  const logOut = () => {

    Cookies.remove('token')
    localStorage.removeItem('profile')
    window.location.href = '/'
  }
  return (
    <BrowserRouter>
      <div className="wrapper">
        <header className="header-top" header-theme="light">
          <div className="container-fluid">
            <div className="d-flex justify-content-between">
              <div className="top-menu d-flex align-items-center">
                <button type="button" className="btn-icon mobile-nav-toggle d-lg-none"><span /></button>
                <button type="button" id="navbar-fullscreen" className="nav-link"><i className="ik ik-maximize" /></button>
              </div>
              <div class="btn bg-danger" style={{ color: 'white' }}>{mode?.toUpperCase()}</div>
              <div className="top-menu d-flex align-items-center">
                <div>{getProfileInformation?.firstName} {getProfileInformation?.lastName} ({getProfileInformation?.type})
                  {RemoteController.isControlling() && <button onClick={() => RemoteController.logOutRemote(getProfileInformation.type == 'super_admin')} type="button" class="btn btn-primary" style={{ marginLeft: 10 }}>{JSON.parse(localStorage.getItem('remoteProfile')).firstName} {JSON.parse(localStorage.getItem('remoteProfile')).lastName} (Çıkış)</button>}

                </div>
                <div className="dropdown">
                  <a className="dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img className="avatar" src="img/default-user-image.png" alt="" /></a>

                  <div className="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
                    <a onClick={() => logOut()} className="dropdown-item"><i className="ik ik-power dropdown-icon" /> Logout</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="page-wrap">
          {!(getProfileInformation.type == "super_admin" && !RemoteController.isControlling()) && <div className="app-sidebar colored">
            <div className="sidebar-header" >
              <a className="header-brand" href="/">
                <div className="logo-img">
                  {/* <img src="#" className="header-brand-img" /> */}
                </div>
                <span className="text">Admin Panel</span>
              </a>
              <button id="sidebarClose" className="nav-close"><i className="ik ik-x" /></button>
            </div>
            <div className="sidebar-content">
              <div className="nav-container">
                <nav id="main-menu-navigation" className="navigation-main">
                  <div className="nav-lavel">İşlemler</div>
                  <div className="nav-item active">
                    <a href="/"><i className="ik ik-bar-chart-2" /><span>Yatırım İslemleri</span></a>
                  </div>
                  <div className="nav-item">
                    <a href="cekimislemleri"><i className="ik ik-menu" /><span>Cekim İslemleri</span> </a>
                  </div>
                  {(checkPermission(getProfileInformation, PERMISSIONS.BANK_ACCOUT_VIEW.key) || checkPermission(getProfileInformation, PERMISSIONS.BANK_ACCOUNT_MANAGEMENT.key)) && <div className="nav-item">
                    <a href="bankahesaplari"><i className="ik ik-command" /><span>{localStorage.getItem('mode')} Hesapları</span></a>
                  </div>}

                  {(getProfileInformation.type == 'admin' || getProfileInformation.type == 'super_admin' || getProfileInformation.type == 'website') && <div className="nav-item">
                    <a href="mutabakat"><i className="ik ik-user" /><span>Mutabakat Ekranı</span></a>
                  </div>}


                  {
                    (getProfileInformation.type == 'super_admin' || getProfileInformation.type == 'admin')
                    // checkPermission(getProfileInformation, PERMISSIONS.USER_MANAGEMENT.key)

                    && <div className="nav-item">
                      <a href="kullaniciac"><i className="ik ik-user" /><span>Kullanıcı Oluştur</span></a>
                    </div>}
                  {
                    (getProfileInformation.type == 'super_admin' || getProfileInformation.type == 'admin')
                    // checkPermission(getProfileInformation, PERMISSIONS.USER_VIEW.key)
                    && <div className="nav-item">
                      <a href="kullanicilar"><i className="ik ik-users" /><span>Kullanıcılar</span></a>
                    </div>}

                  {
                    (getProfileInformation.type == 'super_admin')
                    && <div className="nav-item">
                      <a href="havuzlar"><i className="ik ik-box" /><span>Havuz Ayarları</span></a>
                    </div>
                  }
                  {
                    (getProfileInformation.type == 'super_admin')
                    && <div className="nav-item">
                      <a href="websites"><i className="ik ik-box" /><span>Websiteler</span></a>
                    </div>}
                  {
                    getProfileInformation.type == 'super_admin'
                    && <div className="nav-item">
                      <a href="islemlistesi"><i className="ik ik-list" /><span>İşlem Listesi</span></a>
                    </div>}
                  <div className="nav-item">
                    {mode == 'papara' && getProfileInformation.type == "super_admin" && config.panelSeperated == false && <a style={{ cursor: 'pointer' }} onClick={() => {

                      localStorage.setItem('mode', 'banka')
                      window.location.reload()

                    }}><i className="ik ik-menu" /><span>Banka'ya Geç</span> </a>}

                    {mode != 'papara' && getProfileInformation.type == "super_admin" && config.panelSeperated == false && <a style={{ cursor: 'pointer' }} onClick={() => {


                      localStorage.setItem('mode', 'papara')
                      window.location.reload()
                    }}><i className="ik ik-menu" /><span>Papara'ya Geç</span> </a>}

                    <div className="nav-item">
                      <a href="oyuncular"><i className="ik ik-users" /><span>Oyuncular</span></a>
                    </div>

                  </div>
                </nav>
              </div>
            </div>
          </div>}

          {(getProfileInformation.type == "super_admin" && !RemoteController.isControlling()) && <div className="app-sidebar colored">
            <div className="sidebar-header" >
              <a className="header-brand" href="/">
                <div className="logo-img">
                  {/* <img src="#" className="header-brand-img" /> */}
                </div>
                <span className="text">Admin Panel</span>
              </a>
              <button id="sidebarClose" className="nav-close"><i className="ik ik-x" /></button>
            </div>
            <div className="sidebar-content">
              <div className="nav-container">
                <nav id="main-menu-navigation" className="navigation-main">
                  <div className="nav-lavel">İşlemler</div>
                  <div className="nav-item active">
                    <a href="/super-admin"><i className="ik ik-bar-chart-2" /><span>Kullanıcılar</span></a>
                  </div>
                  <div className="nav-item">
                    <a href="/super-admin-rapor"><i className="ik ik-bar-chart-2" /><span>Raporlar</span></a>
                  </div>
                  <div className="nav-item">
                    <a href="islemlistesi"><i className="ik ik-menu" /><span>İşlem Listesi</span> </a>
                  </div>

                  <div className="nav-item">
                    <a href="havuzlar"><i className="ik ik-box" /><span>Havuz Ayarları</span></a>
                  </div>

                  {
                    (getProfileInformation.type == 'super_admin')
                    && <div className="nav-item">
                      <a href="websites"><i className="ik ik-box" /><span>Websiteleri</span></a>
                    </div>
                  }
                  <div className="nav-item">
                    {mode == 'papara' && config.panelSeperated == false && <a style={{ cursor: 'pointer' }} onClick={() => {

                      localStorage.setItem('mode', 'banka')
                      window.location.reload()

                    }}><i className="ik ik-menu" /><span>Bank'a Geç</span> </a>}
                    {mode != 'papara' && config.panelSeperated == false && <a style={{ cursor: 'pointer' }} onClick={() => {


                      localStorage.setItem('mode', 'papara')
                      window.location.reload()
                    }}><i className="ik ik-menu" /><span>Papara'ya Geç</span> </a>}
                  </div>

                </nav>
              </div>
            </div>
          </div>}
          <Switch>
            {/* <Route path="/" component={Dashboard} exact /> */}
            <Route path="/" component={Home} exact />
            <Route path="/mutabakat" component={Mutabakat} exact />
            <Route path="/islemlistesi" component={IslemListesi} exact />
            <Route path="/cekimislemleri" component={CekimIslemleri} exact />
            <Route path="/bankahesaplari" component={BankaHesaplari} exact />
            <Route path="/manuelyatirim" component={ManuelYatirim} exact />
            <Route path="/kullaniciac" component={KullaniciAc} exact />
            <Route path="/kullanicilar" component={Kullanicilar} exact />
            <Route path="/havuzlar" component={Havuzlar} exact />
            <Route path="/editbank" component={EditBank} exact />
            <Route path="/info" component={Info} exact />
            <Route path="/admintransactionedit" component={AdminTransactionEdit} exact />
            <Route path="/super-admin-rapor" component={SuperAdminRapor} exact />
            {/* <Route path="/external-yatirim" component={ExternalYatirim} exact />
            <Route path="/external-cekim" component={ExternalCekim} exact /> */}
            {/* <Route path="/external-yatirim-fixed-amount" render={() => <ExternalYatirim fixed={true} />} exact /> */}
            {/* <Route path="/external-cekim-fixed-amount" render={() => <ExternalCekim fixed={true} />} exact /> */}
            {/* <Route path="/external-yatirim-papara" render={() => <ExternalYatirimPapara papara={true} />} exact /> */}
            {/* <Route path="/external-cekim-papara" render={() => <ExternalCekimPapara papara={true} />} exact /> */}
            <Route path="/super-admin" component={SuperAdmin} exact />
            <Route path="/oyuncular" component={Players} exact />
            <Route path="/external-common" component={ExternalCommon} exact />
            <Route path="/contact" render={() => <Skype  />} exact />
            {/* <Route path="/external-common-papara" render={() => <ExternalCommon paparaPage={true} />} exact /> */}
            <Route component={NotFoundPage} exact />

          </Switch>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
