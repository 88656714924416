

import { useState, useEffect, useRef } from "react"
import axios from "axios"
import config from "../config.json"
import Cookies from "js-cookie";
import $ from 'jquery';
import DataTable from 'datatables.net';
import moment from 'moment';
import * as RemoteController from "../remoteControl"
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import * as rdrLocales from 'react-date-range/dist/locale';
import ReactToPrint from 'react-to-print';
import dataTableOptions from "../datatableoptions.json"
import InfiniteScroll from "react-infinite-scroll-component";
import io from 'socket.io-client';
import NotificationSound from "./notification-sound.mp3"


function App() {


  const token = RemoteController.getToken()
  const [withdrawTransactions, setWithdrawTransactions] = useState([])
  const [transactionSummary, setTransactionSummary] = useState({})
  const [transactionSummaryWithdraw, setTransactionSummaryWithdraw] = useState({})
  const getProfileInformation = JSON.parse(localStorage.getItem('profile'))
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const [page, setPage] = useState(0);
  const printRef = useRef()
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(socket?.connected);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [hasNext, setHasNext] = useState(true);
  const PAGE_SIZE = 30;
  const startDate = urlParams.get('startDate') || new Date(moment().startOf('day'));
  const endDate = urlParams.get('endDate') || new Date(moment().endOf('day'));
  const [isFetching, setIsFetching] = useState(false)
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(startDate),
    endDate: new Date(endDate),
    key: 'selection',
  })

  const audioPlayer = useRef(null);

  function playAudio() {
    audioPlayer.current.play();
  }

  useEffect(() => {
    const socketC = io(config.serverUrl, {
      query: {
        type: "admin",
        token: token
      }

    });
    setSocket(socketC)
  }, [])


  useEffect(() => {
    if (socket) {


      socket.on('connect', () => {
        console.log("Bağlandı")
        setIsConnected(true);
      });

      socket.on('disconnect', () => {
        setIsConnected(false);
      });

      socket.on('transaction:withdraw', (transaction) => {

        if (transaction?.pool && getProfileInformation?.pool) {
          if (transaction?.pool !== getProfileInformation?.pool) return
        }


        setWithdrawTransactions(prev => [transaction, ...prev])
        window.$(".dataTables_info").text(withdrawTransactions.length + " kayıt listeleniyor")
        playAudio()
      });

      socket.on('transaction:withdraw:update', (transaction) => {

        if (transaction?.pool && getProfileInformation?.pool) {
          if (transaction?.pool !== getProfileInformation?.pool) return
        }


        const prev = withdrawTransactions.map(e => e._id == transaction._id ? { ...e, status: transaction?.status } : e)
        setWithdrawTransactions(prev)
        window.$(".dataTables_info").text(withdrawTransactions.length + " kayıt listeleniyor")
        // getWithdrawTransactions()
        getTransactionSummary()
      });




      return () => {
        socket.off('connect');
        socket.off('disconnect');
        socket.off('pong');
      };
    }
  }, [socket]);



  const sleep = async (ms) => await new Promise((resolve) => setTimeout(() => resolve(''), ms));


  const getWithdrawTransactions = async (_page = page, search) => {
    const papara = localStorage.getItem('mode') == "papara" ? true : false
    setIsFetching(true)
    const req = await axios.get(config.apiUrl + "transactions", {
      params: {
        token: token,
        type: "withdraw",
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        papara,
        skip: _page * PAGE_SIZE,
        limit: PAGE_SIZE,
        ...(search ? { search } : {})
      }
    })
    setIsFetching(false)
  
    if (search) {
      return setFilteredTransactions(req.data)
    }
  
    if (!req?.data?.length || req?.data?.length < PAGE_SIZE) {
      setHasNext(false)
    }
  
    if (Array.isArray(req.data)) {
      try {
        window.$(".dataTables_info").text(withdrawTransactions.length + 1 + " kayıt listeleniyor")
      }
      catch (e) {
  
      }
      const remoteProfile = JSON.parse(localStorage.getItem('remoteProfile'));

      // Tabloya veri eklemeden önce kontrol yapalım
      if (!(remoteProfile?.username === "suatbartu")) {
        setWithdrawTransactions(prev => [...prev, ...req.data]);
      }
    }
  
    const findScriptBefore = document.getElementById("loadscriptDatatable")
    if (!findScriptBefore) {
      setWithdrawTransactions(req.data)
      const script = document.createElement("script");
      script.id = "loadscriptDatatable"
      script.src = "CekimIslemleri.js?v=1.0";
      script.async = true;
      document.body.appendChild(script);
      window.$(".dataTables_info").text(req.data + " kayıt listeleniyor")
    }
  
    // Check if remoteProfile.username is "suatbartu" and website is "https://www.casinoplus205.com"
    const remoteProfile = JSON.parse(localStorage.getItem('remoteProfile'));
    if (remoteProfile?.username === "suatbartu") {
      const filteredData = req.data.filter(item => item.websiteCodeS === "CASINO");
      if (filteredData.length > 0) {
        console.log("Array içinde 'websiteCodeS' key değeri 'CASINO' olan veri bulundu:", filteredData);
      }
    }
  
    setPage((prev) => prev + 1)
  }




  const getTransactionSummary = async () => {
    const papara = localStorage.getItem('mode') == "papara" ? true : false
    setTransactionSummary({})
    const req = await axios.get(config.apiUrl + "transactions/gettransactionsummary", {
      params: {
        token: token,
        type: "withdraw",
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        papara
      }
    })
    setTransactionSummary(req.data)
  }


  function formatMoney(n) {
    try {
      if (!n) return n
      const x = parseFloat(n).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1.').replace(/\.(\d+)$/, ',$1');
      return x
      // return isNaN(x) ? 0 : x
    }
    catch (e) {
      return n
    }
  }



  useEffect(() => {
    Promise.all([
      getWithdrawTransactions(),
      getTransactionSummary()
    ]).then(() => {


    })

  }, [])

  // const validateRejectTransaction = async (status, _id) => {
  //   const req = await axios.post(config.apiUrl + "transactions/verifyrejectdeposit", {
  //     token: token,
  //     _id: _id,
  //     status: status ? 1 : 2,
  //     type: "withdraw"

  //   })
  //   window.location.reload()

  // }

  useEffect(() => {
    if (searchText == "") {
      return setFilteredTransactions([])
    }
    const delayDebounceFn = setTimeout(() => {
      getWithdrawTransactions(0, searchText)
      // Send Axios request here
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [searchText])


  const handleSearch = async (search) => {
    setSearchText(search)

  }

  const tranasctions = searchText ? filteredTransactions : withdrawTransactions



  return (
    <>
      <div className="main-content">
        <div className="container-fluid" ref={printRef}>
          <div class="row">
            <div class="col-md-4 col-sm-4" onClick={() => setShowDateRangePicker(prev => !prev)}>
              <div class="info-box bg-danger">
                <span class="info-box-icon">
                  <i class="fas fa-calendar"></i></span>
                <div class="info-box-content">
                  <span class="info-box-text">Tarih</span>
                  <span class="info-box-number">{moment(startDate).format("DD/MM/YYYY")} - {moment(endDate).format("DD/MM/YYYY")}</span></div>
              </div>
            </div>

            <div class="col-md-4 col-sm-4">
              <div class="info-box bg-warning">
                <span class="info-box-icon">
                  <i class="fas fa-money-bill-wave"></i></span>
                <div class="info-box-content">
                  <span class="info-box-text">Çekilen Tutar</span>
                  <span class="info-box-number">{formatMoney(transactionSummary?.amount)} TL</span></div>
              </div>
            </div>

            <div class="col-md-4 col-sm-4">
              <div class="info-box bg-success">
                <span class="info-box-icon">
                  <i class="fas fa-users"></i></span>
                <div class="info-box-content">
                  <span class="info-box-text">İşlem Adedi</span>
                  <span class="info-box-number">{transactionSummary?.number || 0}</span></div>
              </div>
            </div>

            <div class="col-md-4 col-sm-4">
              <div class="info-box bg-success">
                <div class="info-box-content">
                  <input style={{ height: 50 }} type="text" class="form-control" id="basic-url" aria-describedby="basic-addon3" placeholder="İşlemlerde Ara"
                    onChange={(e) => {
                      handleSearch(e.target.value)
                    }}
                  ></input>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-4">
              <div class="info-box bg-blue btn">
                <span class="info-box-icon">
                  <i class="fas fa-file"></i></span>

                <ReactToPrint
                  documentTitle={"Yatırım Rapor"}
                  trigger={() => {
                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                    // to the root node of the returned component as it will be overwritten.
                    return <a style={{ padding: 20, justifyContent: 'center', alignItems: 'center', display: 'flex', color: '#FFF' }}>PDF Kaydet / Yazdır</a>;
                  }}
                  content={() => printRef.current}
                />
              </div>
              {showDateRangePicker && <><DateRangePicker
                locale={rdrLocales.tr}
                ranges={[selectionRange]}
                onChange={(value) => {
                  setSelectionRange(value.selection);
                  window.location.href = 'cekimislemleri?startDate=' + moment(value.selection.startDate).format('YYYY-MM-DD') + '&endDate=' + moment(value.selection.endDate).format('YYYY-MM-DD')

                }}
              /></>}

            </div>

            {(getProfileInformation.type == 'admin' || true) &&
              <div class="col-md-4 col-sm-4">
                <div className="info-box bg-blue btn" style={{ color: 'white' }}>

                  <a style={{ padding: 20, justifyContent: 'center', alignItems: 'center', display: 'flex', color: 'white' }} href="manuelyatirim">

                    Manuel Yatırım

                  </a>

                </div>
              </div>}



          </div>





          <div className="row clearfix">
            <div>

            </div>
            {/* <select class="form-select btn" aria-label="Default select example" onChange={(e) => setLimit(e.target.value)}>
            <option value="20" selected>20 İşlem</option>
            <option value="50">50 İşlem</option>
            <option value="100">100 İşlem</option>
            <option value="all">Hepsi</option>
          </select> */}
          </div>
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <InfiniteScroll
                dataLength={withdrawTransactions.length} //This is important field to render the next data
                next={() => {
                  getWithdrawTransactions(page)
                }}
                hasMore={hasNext}
                loader={
                  isFetching ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img src="../img/loading.gif" class="rounded-circle" alt="" width={50} height={50} /></div> : <></>
                }
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>Sayfa Sonu</b>
                  </p>
                }
              >
                <table id="example" className="display nowrap" style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>DÜZENLE</th>
                      <th>DURUM</th>
                      {/* <th>WEBSITE</th> */}
                      <th>AD SOYAD</th>
                      <th>MİKTAR</th>
                      <th>BANKA</th>
                      <th>KULLANICI ADI</th>

                      <th>TESLİM ZAMANI</th>
                      <th>İŞLEM ZAMANI</th>
                      <th>ONAY/İPTAL KODU</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tranasctions.length === 0 && !isFetching && <tr><td colSpan={9} style={{ textAlign: 'center' }}>Kayıt Bulunamadı</td></tr>}
                    {
                      tranasctions.map((transaction, index) => {
                        return (
                          <tr key={transaction?._id}>
                            <td>{transaction?.websiteCodeS} {transaction?.transactionId || (index + 1)}</td>
                            <td>
                              {(getProfileInformation.type == 'super_admin') && <button type="button" onClick={() => window.location = '/admintransactionedit?_id=' + transaction._id} className="btn btn-info"><i className="ik ik-check-circle" />Düzenle</button>}
                              <a href={"/info?_id=" + transaction._id}><button type="button" className="btn btn-info btn-rounded">Info</button></a></td>
                            <td>

                              {/* {transaction.status == 0 && <p>Onay Bekliyor</p>} */}
                              {transaction.status != 0 && <button type="button" className={`btn btn-${transaction.status == 1 ? "success" : "danger"}`}><i className="ik ik-check-circle" />{transaction.status == 1 ? "ONAYLANDI" : "REDDEDİLDİ"}</button>}
                            </td>
                            {/* <td>{transaction?.webSiteId}</td> */}
                            <td>{transaction.nameSurname}</td>
                            <td>{formatMoney(transaction.amount)} TL</td>
                            <td>{transaction.bankAccountId?.bankName || ""} {transaction.bankAccountId?.bankName2 ? `(${transaction.bankAccountId?.bankName2})` : ""} - {transaction.bankAccountId?.nameSurname || ""}</td>
                            <td>{transaction.bankAccount?.nameSurname || ""} {transaction.bankAccount?.accountNumber || ""}</td>

                            <td>{moment(transaction.createdAt).format('YYYY-MM-DD HH:mm')}</td>
                            <td>{transaction.status != 0 && moment(transaction.updatedAt).format('YYYY-MM-DD HH:mm')}</td>
                            <td style={{ textAlign: 'center' }}>{transaction.validationCode}</td>

                          </tr>)
                      }
                      )

                    }

                  </tbody>

                </table>
              </InfiniteScroll>
            </div>


          </div>
        </div>

      </div>
      <audio ref={audioPlayer} src={NotificationSound} />
    </>


  );
}

export default App;
