
import { useState, useEffect } from "react"
import axios from "axios"
import config from "../config.json"
import Cookies from "js-cookie";
import $ from 'jquery';
import DataTable from 'datatables.net';
import moment from 'moment';
import { PERMISSIONS, checkPermission } from "../consts/Permissions"
import bankAccounts from "../banks.json"

export default function (props) {
    const getUser = JSON.parse(localStorage.getItem('profile'))
    const token = Cookies.get('token')

    const [bankData, setBankData] = useState({})
    const queryParams = new URLSearchParams(window.location.search);
    const _id = queryParams.get('_id');


    const mode = localStorage.getItem('mode');
    const isPapara = mode == 'papara' ? true : false


    const getBank = async () => {

        const req = await axios.get(config.apiUrl + "bankaccounts/getbankbyid", {
            params: {
                token: token,
                _id: _id
            }
        })
        setBankData(req.data)

    }
    useEffect(() => {
        getBank()

    }, [])

    const updateBankAccount = async () => {

        axios.post(config.apiUrl + "bankaccounts/update", {
            token: token,
            ...bankData,
            _id: bankData._id
        }).then(() => { alert("Başarılı bir şekilde düzenlendi"); window.location.href = '/bankahesaplari' }).catch(e => alert("Düzenleme sırasında Hata"))


    }

    const suspendAndActiveAccount = async (active) => {

        axios.post(config.apiUrl + "bankaccounts/suspendandactiveaccount", {
            token: token,
            _id: bankData._id,
            active: active
        }).then(() => { alert("Başarılı bir şekilde düzenlendi"); window.location.href = '/bankahesaplari' }).catch(e => alert("Düzenleme sırasında Hata"))


    }

    const removeBankAccount = async () => {

        axios.post(config.apiUrl + "bankaccounts/remove", {
            _id: bankData._id,
            token: token
        }).then(() => { alert("Başarılı bir şekilde Silindi"); window.location.href = '/bankahesaplari' }).catch(e => alert("Silme sırasında Hata"))


    }

    const formHandler = (inputName, value, number = false) => {
        setBankData(prev => {
            const newDataForm = new Object({ ...bankData })
            if (number)
                newDataForm[inputName] = Number(value)
            else
                newDataForm[inputName] = value

            return newDataForm
        }

        )
    }

    const formPaparaEmailHandler = (inputName, value, number = false) => {
        setBankData(prev => {
            const newDataForm = new Object({ ...bankData })
            if (number)
                newDataForm['paparaMail'][inputName] = Number(value)
            else
                newDataForm['paparaMail'][inputName] = value

            return newDataForm
        }

        )
    }

    function formatMoney(n) {
        try {


            return parseFloat(n).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1.').replace(/\.(\d+)$/, ',$1');
        }
        catch (e) {
            return n
        }
    }

    if (!checkPermission(getUser, PERMISSIONS.BANK_ACCOUNT_MANAGEMENT.key))
        return (<>No permission</>)

    return (

        <div className="main-content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8">
                        <div className="card">
                            <div className="card-header">
                                <h3>Hesabı Düzenle</h3>
                            </div>
                            <div className="card-body">
                                <div className="mb-20">
                                    <div className="row">
                                        <label className="col-sm-4 col-lg-2 col-form-label">Banka</label>
                                        <div className="col-sm-8 col-lg-10">
                                            <div className="input-group">
                                                <span className="input-group-prepend">
                                                    <label className="input-group-text"><i className="fas fa-university" /></label>
                                                </span>
                                                <select id="banka" name="banka" className="form-control" value={bankData.bankName} onChange={(e) => formHandler("bankName", e.target.value)} >

                                                {bankAccounts.bankAccounts.map(e => <option>{e}</option>)}


                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    {bankData.bankName == "7/24 Fast" && <div className="row">
                                        <label className="col-sm-4 col-lg-2 col-form-label">Banka</label>
                                        <div className="col-sm-8 col-lg-10">
                                            <div className="input-group">
                                                <span className="input-group-prepend">
                                                    <label className="input-group-text"><i className="fas fa-university" /></label>
                                                </span>
                                                <select id="banka" name="banka" className="form-control" value={bankData.bankName2} onChange={(e) => formHandler("bankName2", e.target.value)} >

                                                {bankAccounts.bankAccounts.map(e => e !== "7/24 Fast" && <option>{e}</option>)}


                                                </select>
                                            </div>
                                        </div>
                                    </div>
}

                                    <div className="row">
                                        <label className="col-sm-4 col-lg-2 col-form-label">Hesap Sahibi İsmi</label>
                                        <div className="col-sm-8 col-lg-10">
                                            <div className="input-group">
                                                <span className="input-group-prepend">
                                                    <label className="input-group-text"><i className="fas fa-user" /></label>
                                                </span>
                                                <input type="text" className="form-control" placeholder="İsim" value={bankData.nameSurname} onChange={(e) => formHandler("nameSurname", e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label className="col-sm-4 col-lg-2 col-form-label">Hesap Numarası</label>
                                        <div className="col-sm-8 col-lg-10">
                                            <div className="input-group">
                                                <span className="input-group-prepend">
                                                    <label className="input-group-text"><i className="fas fa-wallet" /></label>
                                                </span>
                                                <input type="text" className="form-control" placeholder="Hesap No" value={bankData.accountNumber} onChange={(e) => formHandler("accountNumber", e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label className="col-sm-4 col-lg-2 col-form-label">Şube Kodu</label>
                                        <div className="col-sm-8 col-lg-10">
                                            <div className="input-group">
                                                <span className="input-group-prepend">
                                                    <label className="input-group-text"><i className="fas fa-hashtag" /></label>
                                                </span>
                                                <input type="text" className="form-control" placeholder="--" value={bankData.branchCode} onChange={(e) => formHandler("branchCode", e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label className="col-sm-4 col-lg-2 col-form-label">IBAN</label>
                                        <div className="col-sm-8 col-lg-10">
                                            <div className="input-group">
                                                <span className="input-group-prepend">
                                                    <label className="input-group-text"><i className="fas fa-money-check" /></label>
                                                </span>
                                                <input type="text" className="form-control" placeholder="IBAN" value={bankData.iban} onChange={(e) => formHandler("iban", e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label className="col-sm-4 col-lg-2 col-form-label">Papara Yatırım için Paylaşılıyor</label>
                                        <div className="col-sm-8 col-lg-10">
                                            <div className="input-group">
                                                <span className="input-group-prepend">
                                                    <label className="input-group-text"><i className="fas fa-info-circle" /></label>
                                                </span>
                                                <select id="banka" name="banka" className="form-control">
                                                    <option value="saab">Evet</option>
                                                    <option value="fiat">Hayır</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label className="col-sm-4 col-lg-2 col-form-label">Bakiye</label>
                                        <div className="col-sm-8 col-lg-10">
                                            <div className="input-group">
                                                <span className="input-group-prepend">
                                                    <label className="input-group-text"><i className="fas fa-money-bill-alt" /></label>
                                                </span>
                                                <input type="text" className="form-control" placeholder="Bakiye" value={bankData.balance} onChange={(e) => formHandler("balance", e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <label className="col-sm-4 col-sm-2 col-form-label">Yatırım Alt Limit</label>
                                            <div className="input-group">
                                                <span className="input-group-prepend"><label className="input-group-text"><i className="fas fa-arrow-down" /></label></span>
                                                <input type="text" className="form-control" placeholder={'0000'} value={bankData.minimumDepositLimit} onChange={(e) => formHandler("minimumDepositLimit", e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <label className="col-sm-6 col-sm-4 col-form-label">Yatırım Üst Limit</label>
                                            <div className="input-group input-group-default">
                                                <span className="input-group-prepend"><label className="input-group-text"><i className="fas fa-arrow-up" /></label></span>
                                                <input type="text" className="form-control" placeholder={'9999'} value={bankData.maximumDepositLimit} onChange={(e) => formHandler("maximumDepositLimit", e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <label className="col-sm-4 col-sm-2 col-form-label">Çekim Alt Limit</label>
                                            <div className="input-group">
                                                <span className="input-group-prepend"><label className="input-group-text"><i className="fas fa-arrow-down" /></label></span>
                                                <input type="text" className="form-control" placeholder={'0000'} value={bankData.minimumWithdrawLimit} onChange={(e) => formHandler("minimumWithdrawLimit", e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <label className="col-sm-4 col-sm-2 col-form-label">Çekim Üst Limit</label>
                                            <div className="input-group input-group-default">
                                                <span className="input-group-prepend"><label className="input-group-text"><i className="fas fa-arrow-up" /></label></span>
                                                <input type="text" className="form-control" placeholder={'9999'} value={bankData.maximumWithdrawLimit} onChange={(e) => formHandler("maximumWithdrawLimit", e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <label className="col-sm-8 col-sm-6 col-form-label">Toplam Alınabilecek Yatırım Adedi</label>
                                            <div className="input-group">
                                                <span className="input-group-prepend"><label className="input-group-text"><i className="far fa-plus-square" /></label></span>
                                                <input type="text" className="form-control" placeholder={'9999'} value={bankData.accountDepositLimitTransactionNumber} onChange={(e) => formHandler("accountDepositLimitTransactionNumber", e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <label className="col-sm-8 col-sm-6 col-form-label">Toplam Alınabilecek Çekim Adedi</label>
                                            <div className="input-group input-group-default">
                                                <span className="input-group-prepend"><label className="input-group-text"><i className="far fa-plus-square" /></label></span>
                                                <input type="text" className="form-control" placeholder={'9999'} value={bankData.accountWithdrawLimitTransactionNumber} onChange={(e) => formHandler("accountWithdrawLimitTransactionNumber", e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label className="col-sm-8 col-lg-6 col-form-label">Hesaba İlişkin Ek Bilgi(Siteye Gösterilecek)</label>
                                        <div className="col-sm-8 col-lg-10">
                                            <div className="input-group">
                                                <span className="input-group-prepend">
                                                    <label className="input-group-text"><i className="fas fa-comment-dots" /></label>
                                                </span>
                                                <input type="text" className="form-control" placeholder="Ek Bilgi" value={bankData.additionalDescription} onChange={(e) => formHandler("additionalDescription", e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label className="col-sm-8 col-lg-6 col-form-label">Yatırımda Müşteriden İstenecek Açıklama</label>
                                        <div className="col-sm-8 col-lg-10">
                                            <div className="input-group">
                                                <span className="input-group-prepend">
                                                    <label className="input-group-text"><i className="fas fa-comment-dots" /></label>
                                                </span>
                                                <input type="text" className="form-control" placeholder="Açıklama" value={bankData.description} onChange={(e) => formHandler("description", e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                   {isPapara && <>
                                    <div className="form-group">
                      <label className="control-label">Papara Hesabının Mail Adresi</label>
                      <div>
                        <input type="text" className="form-control input" name="Hesap Numarası" value={bankData?.paparaMail?.email} onChange={(e) => formPaparaEmailHandler("email", e.target.value)} />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-label">Mail Şifresi</label>
                      <div>
                        <input type="text" className="form-control input" name="Hesap Numarası" value={bankData?.paparaMail?.password} onChange={(e) => formPaparaEmailHandler("password", e.target.value)} />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="control-label">Mail Hoster</label>
                      <select id="yatirim" name="yatirim" className="form-control" value={bankData?.paparaMail?.type} onChange={(e) => formHandler("type", e.target.value)}>
                        <option value="gmail">Gmail</option>
                      </select>
                    </div>
                                    </>}

                                    <div className="row">
                                        <div className="col-sm-8 col-lg-10 d-flex justify-content-center">
                                            <button type="button" onClick={() => updateBankAccount()} className="btn btn-primary" style={{ width: '50%', fontSize: '16px' }}>Hesabı Güncelle</button>
                                        </div>
                                    </div>
                                </div>
                                {/* Basic group add-ons end */}
                                {/* Icon Group Addons start */}
                                <div>
                                </div>
                                {/* Icon Group Addons end */}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="card" style={{ minHeight: '300px' }}>
                            <div className="card-header"><h3>Hesap Bilgileri</h3></div>
                            <div className="card-body">
                                <form>
                                    <div className="form-group row">
                                        <label htmlFor="staticEmail" className="col-sm-6 col-form-label"> Düzenlenen Hesap ID: </label>
                                        <div className="col-sm-4">
                                            <input type="text" readOnly className="form-control-plaintext" id="staticEmail" value={bankData._id} />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="staticEmail" className="col-sm-6 col-form-label">Bakiye: </label>
                                        <div className="col-sm-6">
                                            <input type="text" readOnly className="form-control-plaintext" id="staticEmail" value={formatMoney(bankData.balance) + " TL"} />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="staticEmail" className="col-sm-6 col-form-label">Kalan Yatırım Adedi: </label>
                                        <div className="col-sm-6">
                                            <input type="text" readOnly className="form-control-plaintext" id="staticEmail" value={bankData.accountDepositLimitTransactionNumber - bankData.depositNumber} />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="staticEmail" className="col-sm-6 col-form-label">Kalan Çekim Adedi: </label>
                                        <div className="col-sm-6">
                                            <input type="text" readOnly className="form-control-plaintext" id="staticEmail" value={bankData.accountWithdrawLimitTransactionNumber - bankData.withdrawNumber} />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="staticEmail" className="col-sm-6 col-form-label">Oluşturulma Tarihi: </label>
                                        <div className="col-sm-6">
                                            <input type="text" readOnly className="form-control-plaintext" id="staticEmail" value={moment(bankData.createdAt).format('YYYY-MM-DD HH:mm')} />
                                        </div>
                                    </div>
                                    <div className="form-group row ">
                                        <label htmlFor="staticEmail" className="col-sm-6 col-form-label">Son İşlem: </label>
                                        <div className="col-sm-6">
                                            <input type="text" readOnly className="form-control-plaintext" id="staticEmail" value={moment(bankData.lastTransactionDate).format('YYYY-MM-DD HH:mm')} />
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{ width: '100%' }}>
                                        <div className="col-lg-12 d-flex justify-content-around">
                                            <button type="button" className="btn btn-danger" style={{ width: '40%', fontSize: '16px' }} onClick={() => removeBankAccount()}>Sil</button>
                                            <button type="button" className="btn btn-warning" style={{ width: '40%', fontSize: '16px' }} onClick={() => suspendAndActiveAccount(false)} >Askıya Al</button>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{ width: '100%' }}>
                                        <div className="col-lg-12">
                                            <button type="button" className="btn btn-success" style={{ width: '100%', fontSize: '16px' }} onClick={() => suspendAndActiveAccount(true)}>Aktif Et</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}