
import { useState, useEffect } from "react"
import axios from "axios"
import config from "../config.json"
import Cookies from "js-cookie";
import $ from 'jquery';
import DataTable from 'datatables.net';
import moment from 'moment';
import * as RemoteController from "../remoteControl"
export default function App() {

    const token = RemoteController.getToken()
    const [avaibleAccounts, setAvaibleAccounts] = useState([])
    const [formData, setFormData] = useState({
        website: "",
        amount: "",
        transactionId: "",
        userIdInWebsite: "",
        nameSurname: "",
        bankAccountId: "",
        transactionTime: ""
    })
    const formHandler = (inputName, value, number = false) => {
        setFormData(prev => {
            const newDataForm = new Object({ ...formData })
            if (number)
                newDataForm[inputName] = Number(value)
            else
                newDataForm[inputName] = value

            return newDataForm
        }

        )
    }

    const createManualDeposit = () => {
        axios.post(config.apiUrl + "transactions/manueldeposit", {
            token: token,
            ...formData
        }).then(() => { alert("Başarılı bir şekilde eklendi"); window.location = '/' }).catch(e => alert("Ekleme sırasında hata" + e))
    }
    const getAvaibleAccounts = async () => {
        const req = await axios.get(config.apiUrl + "bankaccounts/getavaiblebankaccounts", {
            params: {
                token: token
            }
        })

        setAvaibleAccounts(req.data)
    }

    useEffect(() => {
        getAvaibleAccounts()
    }, [])

    return (
        <div className="main-content">
            <div className="container-fluid">
                <div className="page-header">
                    <div className="row align-items-end">
                        <div className="col-lg-4">
                            <nav className="breadcrumb-container" aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="../index.html"><i className="ik ik-home" /></a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <a href="../index.html">Yatırım İşlemleri</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Manuel Yatırım</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header"><h3>Manuel Yatırım</h3></div>
                            <div className="card-body">
                                <form className="forms-sample">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail3">Kullanıcı Adı</label>
                                                <input type="text" className="form-control is-invalid" id="exampleInputText3" placeholder="Kullanıcı Adı" disabled value={JSON.parse(localStorage.getItem('profile')).username} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail3">Kullanıcı Site ID</label>
                                                <input type="number" className="form-control is-invalid" id="exampleInputNumber3" placeholder="Yatırım yapan kullanıcının sitede ki ID'si" onChange={(e) => formHandler("userIdInWebsite", e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputText3">Site</label>
                                                <select className="form-control is-invalid" id="exampleInputEmail3" onChange={(e) => formHandler("website", e.target.value)} >
                                                    <option value={0}>Lütfen Siteyi Seçin</option>
                                                    <option value={1}>*</option>
                                                    <option value={2}>**</option>
                                                    <option value={3}>***</option>
                                                    <option value={4}>****</option>
                                                    <option value={5}>*****</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail3">Açık İsim</label>
                                                <input type="text" className="form-control is-invalid" id="exampleInputEmail3" placeholder="Açık İsim" onChange={(e) => formHandler("nameSurname", e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail3">Miktar</label>
                                                <input type="number" className="form-control is-invalid" id="exampleInputEmail3" placeholder="Miktar" onChange={(e) => formHandler("amount", e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail3">Yatırımın Yapıldığı Hesap</label>
                                                <select className="form-control is-invalid" id="exampleInputEmail3" onChange={(e) => formHandler("bankAccountId", e.target.value)} >
                                                    <option value={""}>Lütfen yatırımın yapıldığı hesabı seçin</option>
                                                    {avaibleAccounts.map(account => <option value={account._id}>{account.nameSurname} -  {account.bankName}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail3">İşlem ID</label>
                                                <input type="text" className="form-control is-invalid" id="exampleInputEmail3" placeholder="Site tarafındaki işlem ID'si" onChange={(e) => formHandler("transactionId", e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail3">Yatırım Saati</label>
                                                <input className="form-control is-invalid" type="datetime-local" onChange={(e) => formHandler("transactionTime", e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <button type="submit" className="btn btn-primary mr-2" onClick={() => createManualDeposit()}>Submit</button>
                                <button className="btn btn-light">Cancel</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}