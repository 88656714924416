
import { useState, useEffect } from "react"
import axios from "axios"
import config from "../config.json"
import Cookies from "js-cookie";
import $ from 'jquery';
import DataTable from 'datatables.net';
import moment from 'moment';
import * as RemoteController from "../remoteControl"
import { PERMISSIONS, checkPermission } from "../consts/Permissions"
import bankAccounts from "../banks.json"
function App() {
  const token = RemoteController.getToken()
  const [avaibleAccounts, setAvaibleAccounts] = useState([])
  const [limitExceedAccounts, setLimitExceedAccounts] = useState([])
  const [suspendedAccounts, setSuspendedAccounts] = useState([])
  const [allAccounts, setAllAccounts] = useState([])

  const mode = localStorage.getItem('mode');
  const isPapara = mode == 'papara' ? true : false
  
  const [formData, setFormData] = useState({
    bankName: isPapara ? "Papara" : "",
    nameSurname: "",
    accountNumber: "",
    branchCode: "",
    iban: "",
    investmentType: "papara",
    additionalDescription: "",
    description: "",
    accountDepositLimitTransactionNumber: "", // Yatırılabilir Adet
    accountWithdrawLimitTransactionNumber: "", // Çekilebilir Adet
    description2: "",
    minimumDepositLimit: "", // Yatırım Alt limit
    maximumDepositLimit: "", // Yatırım Üst limit
    minimumWithdrawLimit: "", // Ödeme Alt Limit
    maximumWithdrawLimit: "", // Ödeme Üst limit
    singularDepositForAccountPassive: "", // Teki Yatırım Limiti
    institutional: false, // Kurumsal mı


    mailHost: 'gmail',
  })
  
  const getUser = JSON.parse(localStorage.getItem('profile'))

  const formValidator = (data, name, required = false, number = false) => {
    let message = ""
    if (required && data == "") {
      message += name + " boş bırakılmamalı\n"
    }

    // if (typeof message != 'number' && number) {
    //   message += name + " sayı olmalı\n"
    // }

    if (message != "") {
      return message
    }
    return ""


  }


  useEffect(() => {
    getAvaibleAccounts()
    getSuspendedBankAccounts()
    getTransactionLimitExceedAccounts()
    getAllAccounts()

    const script = document.createElement("script");
    script.src = "bankDataTable.js";
    script.async = true;
    document.body.appendChild(script);
  }, [])

  const getAvaibleAccounts = async () => {
    const papara = localStorage.getItem('mode') == 'papara' ? true : false
    const req = await axios.get(config.apiUrl + "bankaccounts/getavaiblebankaccounts", {
      params: {
        token: token,
        papara
      }
    })

    setAvaibleAccounts(req.data)

  }

  const getSuspendedBankAccounts = async () => {
    const papara = localStorage.getItem('mode') == 'papara' ? true : false
    const req = await axios.get(config.apiUrl + "bankaccounts/getsuspendedbankaccounts", {
      params: {
        token: token,
        papara
      }
    })

    setSuspendedAccounts(req.data)

  }

  const suspendAndActiveAccount = async (_id, active) => {
    const papara = localStorage.getItem('mode') == 'papara' ? true : false
    axios.post(config.apiUrl + "bankaccounts/suspendandactiveaccount", {
      token: token,
      _id: _id,
      active: active,
      papara
    }).then(() => { alert("Başarılı bir şekilde düzenlendi"); window.location.href = '/bankahesaplari' }).catch(e => alert("Düzenleme sırasında Hata"))


  }

  const getTransactionLimitExceedAccounts = async () => {
    const papara = localStorage.getItem('mode') == 'papara' ? true : false
    const req = await axios.get(config.apiUrl + "bankaccounts/gettransactionlimitexceedaccounts", {
      params: {
        token: token,
        papara
      }
    })

    setLimitExceedAccounts(req.data)

  }


  const removeBankAccount = async (_id) => {

    axios.post(config.apiUrl + "bankaccounts/remove", {
      _id: _id,
      token: token
    }).then(() => { alert("Başarılı bir şekilde Silindi"); window.location.href = '/bankahesaplari' }).catch(e => alert("Silme sırasında Hata"))


  }

  const getAllAccounts = async () => {
    const papara = localStorage.getItem('mode') == 'papara' ? true : false
    const req = await axios.get(config.apiUrl + "bankaccounts/", {
      params: {
        token: token,
        papara
      }
    })


    setAllAccounts(req.data)

  }


  if (!(checkPermission(getUser, PERMISSIONS.BANK_ACCOUNT_MANAGEMENT.key) || checkPermission(getUser, PERMISSIONS.BANK_ACCOUT_VIEW.key)))
    return (<>No permission</>)

  const generateTable = (data, tableName) => {

    return (
      <table id={tableName} className="table table-striped table-bordered" cellSpacing={0} width="100%">
        <thead>
          <tr>
            <th>ID</th>
            <th>Banka</th>
            <th>Hesap Sahibi İsmi</th>
            <th>Hesap Numarası</th>
            <th>Bakiye</th>
            <th>Yatırım Adeti</th>
            <th>Çekim Adeti</th>
            <th>Eklendiği Tarih</th>
            <th>Düzenle</th>
          </tr>
        </thead>
        <tbody>
          {data.map((account, index) => {
            return (
              <tr>
                <td>{index + 1}</td>
                <td>
                  <span>{account.bankName}</span>
                {isPapara && <div><button type="button" className={"btn btn-xs " + account?.paparaMail?.connected == "connected" ? "btn-success" : "btn-danger"  }>
                {account?.paparaMail?.connected == "connected" ? "Bağlı" : "Bağlı Değil"}
                </button>
                </div>}
                </td>
                <td>{account.nameSurname}</td>
                <td>{account.accountNumber}</td>
                <td>{account.balance} TL</td>
                <td>{account.depositNumber}</td>
                <td>{account.withdrawNumber}</td>
                <td>{moment(account.createdAt).format("DD/MM/YYYY HH:mm")}</td>
                {/* <td>{moment(account.lastTransactionDate).format("DD/MM/YYYY HH:mm")}</td> */}
                {checkPermission(getUser, PERMISSIONS.BANK_ACCOUNT_MANAGEMENT.key) && <td>
                  <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <button type="button" className="btn btn-primary btn-xs dt-edit" onClick={() => window.location = 'editbank?_id=' + account._id}>
                      <i className="fas fa-edit" style={{ display: 'flex', justifyContent: 'center' }} />
                    </button>
                    <button type="button" onClick={() => removeBankAccount(account._id)} className="btn btn-danger btn-xs dt-delete">
                      <i className="fas fa-trash" />
                    </button>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '8px' }}>
                    <button type="button" className="btn btn-danger">
                      <i className="fas fa-minus-circle" onClick={() => suspendAndActiveAccount(account._id, false)} />
                    </button>
                    <button type="button" className="btn btn-primary btn-xs dt-edit">
                      <i className="fas fa-search" />
                    </button>
                  </div>
                </td>}
                {
                  !checkPermission(getUser, PERMISSIONS.BANK_ACCOUNT_MANAGEMENT.key) && <td></td>

                }
              </tr>

            )
          })}

        </tbody>
      </table>

    )

  }

  const formHandler = (inputName, value, number = false) => {
    setFormData(prev => {
      const newDataForm = new Object({ ...formData })
      if (number)
        newDataForm[inputName] = Number(value)
      else
        newDataForm[inputName] = value

      return newDataForm
    }

    )
  }

  const createNewBankAccount = () => {


    let errorMessage = ""
    // errorMessage += formValidator(formData.bankName, "bankName", true, false)
    // errorMessage += formValidator(formData.nameSurname, "nameSurname", true, false)
    // errorMessage += formValidator(formData.accountNumber, "accountNumber", true, false)
    // errorMessage += formValidator(formData.branchCode, "branchCode", false, false)
    // errorMessage += formValidator(formData.iban, "iban", true, false)
    // errorMessage += formValidator(formData.additionalDescription, "additionalDescription", false, false)
    // errorMessage += formValidator(formData.description, "description", false, false)
    // errorMessage += formValidator(formData.accountDepositLimitTransactionNumber, "accountDepositLimitTransactionNumber", true, true)
    // errorMessage += formValidator(formData.accountWithdrawLimitTransactionNumber, "accountWithdrawLimitTransactionNumber", true, true)
    // errorMessage += formValidator(formData.description2, "description2", true, false)
    // errorMessage += formValidator(formData.minimumDepositLimit, "minimumDepositLimit", true, true)
    // errorMessage += formValidator(formData.maximumDepositLimit, "maximumDepositLimit", true, true)
    // errorMessage += formValidator(formData.minimumWithdrawLimit, "minimumWithdrawLimit", true, true)
    // errorMessage += formValidator(formData.maximumWithdrawLimit, "maximumWithdrawLimit", true, true)
    // errorMessage += formValidator(formData.singularDepositForAccountPassive, "bankName", false, true)
    if (errorMessage == "")
     {
      const papara = localStorage.getItem('mode') == 'papara' ? true : false
      axios.post(config.apiUrl + "bankaccounts/create", {
        ...formData,
        token: token,
        papara
      }).then(() => { alert("Başarılı bir şekilde eklendi"); window.location.reload() }).catch(e => alert("Ekleeme sırasında Hata"))}
    else
      alert(errorMessage)
  }
  return (
    <div className="main-content">
      <title>Banka Hesapları | Admin Panel</title>
      <div className="container-fluid">
        <div className="container p-5">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <a data-toggle="tab" className="nav-link active" href="#home"><i className="fas fa-asterisk" />Açık Hesaplar</a>
            </li>
            {/* <li className="nav-item">
              <a data-toggle="tab" className="nav-link" href="#profile"><i className="fas fa-asterisk" /> Ödeme Limiti Dolanlar </a>
            </li> */}
            <li className="nav-item">
              <a data-toggle="tab" className="nav-link" href="#profile1"><i className="fas fa-asterisk" />Adet Limiti Dolanlar</a>
            </li><li className="nav-item">
              <a data-toggle="tab" className="nav-link" href="#profile2"><i className="fas fa-asterisk" /> Askıdaki Hesaplar</a>
            </li>
            <li className="nav-item">
              <a data-toggle="tab" className="nav-link" href="#profile3"><i className="fas fa-asterisk" /> Kullanılmış Hesaplar</a>
            </li>
          </ul>
          {/* Button trigger modal */}
          <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModalLong" style={{ marginTop: '16px' }}>
            Yeni Hesap Ekle
          </button>
          {/* Modal */}
          <div className="modal fade" id="exampleModalLong" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">Yeni Hesap Ekle</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form role="form" method="POST" action>
                    <input type="hidden" name="_token" defaultValue />
                    {!isPapara && <div className="form-group">
                      <label htmlFor="banka" className="control-label">Banka</label>
                      <select id="banka" name="banka" className="form-control" onChange={(e) => formHandler("bankName", e.target.value)}>
                        <option value="">Banka Seçimi</option>
                        {bankAccounts.bankAccounts.map(e => <option>{e}</option>)}
                      </select>
                    </div>}

                    {formData.bankName == "7/24 Fast" && <div className="form-group">
                      <label htmlFor="banka" className="control-label">Banka Seçin</label>
                      <select id="banka2" name="banka2" className="form-control" onChange={(e) => formHandler("bankName2", e.target.value)}>
                        <option value="">Banka Seçimi</option>
                        {bankAccounts.bankAccounts.map(e => e !== "7/24 Fast" && <option>{e}</option>)}
                      </select>
                    </div>}

                    <div className="form-group">
                      <label className="control-label">İsim</label>
                      <div>
                        <input required type="text" className="form-control input" name="Hesap Sahibi İsmi" onChange={(e) => formHandler("nameSurname", e.target.value)} />
                      </div>
                    </div>

                    
                    <div className="form-group">
                      <label className="control-label">Hesap Numarası</label>
                      <div>
                        <input type="text" className="form-control input" name="Hesap Numarası" onChange={(e) => formHandler("accountNumber", e.target.value)} />
                      </div>
                    </div>

                  {isPapara && <> 
                    <div className="form-group">
                      <label className="control-label">Papara Hesabının Mail Adresi</label>
                      <div>
                        <input type="text" className="form-control input" name="Hesap Numarası" onChange={(e) => formHandler("paparaMail", e.target.value)} />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-label">Mail Şifresi</label>
                      <div>
                        <input type="text" className="form-control input" name="Hesap Numarası" onChange={(e) => formHandler("paparaMailPassword", e.target.value)} />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="control-label">Mail Hoster</label>
                      <select id="yatirim" name="yatirim" className="form-control" onChange={(e) => formHandler("mailHost", e.target.value)}>
                        <option value="gmail">Gmail</option>
                        <option value="outlook">Outlook / Hotmail</option>
                      </select>
                    </div>
                    </>
                    }


                    {!isPapara && <div className="form-group">
                      <label className="control-label">Şube Kodu</label>
                      <div>
                        <input type="text" className="form-control input" name="Şube Kodu" onChange={(e) => formHandler("branchCode", e.target.value)} />
                      </div>
                    </div>}
                    {!isPapara &&<div className="form-group">
                      <label className="control-label">IBAN</label>
                      <div>
                        <input type="text" className="form-control input" name="IBAN" onChange={(e) => formHandler("iban", e.target.value)} />
                      </div>
                    </div>}
                    <div className="form-group">
                      <label className="control-label">Yatırım</label>
                      <select id="yatirim" name="yatirim" className="form-control" onChange={(e) => formHandler("investmentType", e.target.value)}>
                        <option value="papara">Papara Yatırım İçin Paylaşılıyor</option>
                        <option value="saab">*</option>
                        <option value="fiat">**</option>
                        <option value="audi">***</option>
                        <option value="audi">****</option>
                        <option value="audi">*****</option>
                        <option value="audi">******</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label className="control-label">Ek Bilgi</label>
                      <div>
                        <input type="text" className="form-control input-lg" name="Ek Bilgi" onChange={(e) => formHandler("additionalDescription", e.target.value)} />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-label" >Açıklama</label>
                      <div>
                        <input type="text" className="form-control input-lg" name="Açıklama" onChange={(e) => formHandler("description", e.target.value)} />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-label">Yatırım Adet Limiti</label>
                      <div>
                        <input type="text" className="form-control input-lg" name="Yatırım Adet Limiti" onChange={(e) => formHandler("accountDepositLimitTransactionNumber", e.target.value, true)} />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-label">Çekim Adet Limiti</label>
                      <div>
                        <input type="text" className="form-control input-lg" name="Çekim Adet Limiti" onChange={(e) => formHandler("accountWithdrawLimitTransactionNumber", e.target.value, true)} />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-label">Açıklama</label>
                      <div>
                        <input type="text" className="form-control input-lg" name="Açıklama" onChange={(e) => formHandler("description2", e.target.value)} />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="input-group input-group-default">
                            <span className="input-group" />
                            <input type="number" className="form-control" placeholder="Yatırım Alt Limit" onChange={(e) => formHandler("minimumDepositLimit", e.target.value, true)} />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="input-group input-group-default">
                            <span className="input-group" />
                            <input type="number" className="form-control" placeholder="Yatırım Üst Limit" onChange={(e) => formHandler("maximumDepositLimit", e.target.value, true)} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="input-group input-group-default">
                            <span className="input-group" />
                            <input type="number" className="form-control" placeholder="Çekim Alt Limit" onChange={(e) => formHandler("minimumWithdrawLimit", e.target.value, true)} />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="input-group input-group-default">
                            <span className="input-group" />
                            <input type="number" className="form-control" placeholder="Çekim Üst Limit" onChange={(e) => formHandler("maximumWithdrawLimit", e.target.value, true)} />
                          </div>
                        </div>
                      </div>
                      <span style={{ color: 'red' }}>Hesaba yüklü miktar yatırım yapıldığı zaman sistem hesabı otomatik olarak askıya alacaktır.
                        Bu miktarı aşağıya gireceğiniz değer belirleyecektir.
                      </span>
                    </div>
                    <div className="form-group">
                      <div>
                        <input type="text" className="form-control input-lg" placeholder="Hesabı Pasife Alacak Tekil Yatırım Miktarı" onChange={(e) => formHandler("singularDepositForAccountPassive", e.target.value, true)} />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="border-checkbox-group border-checkbox-group-primary">
                        <input className="border-checkbox" type="checkbox" id="checkbox1" onChange={(e) => formHandler("institutional", e.target.checked)} />
                        <label className="border-checkbox-label" htmlFor="checkbox1">Kurumsal Hesap</label>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Vazgeç</button>
                  <button type="button" className="btn btn-primary" onClick={() => createNewBankAccount()}> Hesabı Kaydet</button>
                </div>
              </div>
            </div>
          </div>
          {/* Tab panes */}
          <div className="tab-content">
            <div class="tab-pane active py-3" id="home">

              {generateTable(avaibleAccounts, "example")}

            </div>

            <div class="tab-pane active py-3 fade" id="profile1">

              {generateTable(limitExceedAccounts, "example2")}

            </div>

            <div class="tab-pane active py-3 fade" id="profile2">

              {generateTable(suspendedAccounts, "example3")}

            </div>


            <div class="tab-pane active py-3 fade" id="profile3">

              {generateTable(allAccounts, "example4")}

            </div>







          </div>
        </div>
      </div>

    </div >
  );
}

export default App;
