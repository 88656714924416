

function App() {
  return (
    <div>
      <meta charSet="utf-8" />
      <title>Page Not Found</title>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link href="https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800" rel="stylesheet" />
      <style dangerouslySetInnerHTML={{ __html: "\n        *, body {\n            -webkit-font-smoothing: antialiased;\n            text-rendering: optimizeLegibility;\n            -moz-osx-font-smoothing: grayscale;\n        }\n        * {\n            line-height: 1.2;\n            margin: 0;\n        }\n\n        html {\n            color: #888;\n            display: table;\n            font-family: 'Nunito Sans', sans-serif;\n            height: 100%;\n            text-align: center;\n            width: 100%;\n        }\n\n        .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {\n            font-family: 'Nunito Sans', sans-serif;\n        }\n\n        body {\n            display: table-cell;\n            vertical-align: middle;\n            margin: 2em auto;\n        }\n\n        h1 {\n            color: #ef4153;\n            text-shadow: rgba(235, 82, 93, 0.3) 5px 1px, rgba(235, 82, 93, 0.2) 10px 3px;\n            font-size: 150px;\n            font-weight: 800;\n            margin-bottom: 10px;\n            letter-spacing: 2px;\n        }\n        h4 {\n            color: #4a5361;\n            text-transform: capitalize;\n            font-size: 28px;\n        }\n\n        p {\n            margin: 0 auto;\n            max-width: 790px;\n            margin-top: 20px;\n            color: #666 ;\n            margin-bottom: 10px;\n            font-size: 15px;\n            line-height: 20px;\n        }\n        a {\n            display: inline-block;\n            padding: 8px 15px;\n            background-color: #ef4153;\n            color: #fff;\n            text-decoration: none;\n            border-radius: 4px;\n            margin-top: 20px;\n        }\n\n        @media only screen and (max-width: 280px) {\n\n            body, p {\n                width: 95%;\n            }\n\n            h1 {\n                font-size: 1.5em;\n                margin: 0 0 0.3em;\n            }\n\n        }\n\n    " }} />
      <h1>404</h1>
      <h4>Page Not Found</h4>
      <p>It's looking like you may have taken a wrong turn. Don't worry... it happens to the best of us. Here's a little tip that might help you get back on track.</p>
      <a href="/">Return to Home</a>
      {/* IE needs 512+ bytes: http://blogs.msdn.com/b/ieinternals/archive/2010/08/19/http-error-pages-in-internet-explorer.aspx */}
    </div>
  );
}

export default App;
