

import { useState, useEffect } from "react"
import axios from "axios"
import config from "../config.json"
import Cookies from "js-cookie";
import $ from 'jquery';
import DataTable from 'datatables.net';
import moment from 'moment';
import * as RemoteController from "../remoteControl"
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import * as rdrLocales from 'react-date-range/dist/locale';

function App() {
  const token = RemoteController.getToken()
  const [depositTransactions, setDepositTransactions] = useState([])
  const [transactionSummary, setTransactionSummary] = useState({})
  const [transactionSummaryWithdraw, setTransactionSummaryWithdraw] = useState({})
  const getProfileInformation = JSON.parse(localStorage.getItem('profile'));
  const [showDateRangePicker, setShowDateRangePicker] = useState(false)
  const urlParams = new URLSearchParams(window.location.search);
  const startDate = urlParams.get('startDate') || new Date(moment().startOf('day'));
  const endDate = urlParams.get('endDate') || new Date(moment().endOf('day'));
  const [isLoading, setIsLoading] = useState(false)
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(startDate),
    endDate: new Date(endDate),
    key: 'selection',
  });

  const [state, setState] = useState({
    type: 'all',
    status: 'all',
    nameSurname: '',
    website: '',
    user_id: '',
    price_min: 0,
    price_max: 100000,
    startDate: new Date(moment().startOf('day')),
    endDate: new Date(moment().endOf('day'))

  });


  const _setState = (obj) => {

    setState(prev => ({ ...prev, ...obj }))

  }

  useEffect(() => {

    setIsLoading(true)
    getDepositTransactions()



  }, [state])

  const getDepositTransactions = async (selection) => {
    const papara = await localStorage.getItem('mode') == 'papara' ? true : false
    setDepositTransactions([])
    const req = await axios.get(config.apiUrl + "transactions/islemlistesi", {
      params: {
        token: token,
        ...state,
        startDate: selection ? moment(selection.startDate).format('YYYY-MM-DD') : moment(selectionRange.startDate).format('YYYY-MM-DD'),
        endDate: selection ? moment(selection.endDate).format('YYYY-MM-DD') : moment(selectionRange.endDate).format('YYYY-MM-DD'),
        papara,
        skip: 0,
        limit: 20
      }
    })
    setIsLoading(false)
    setDepositTransactions(req.data)
  }


  const getTransactionSummary = async () => {
    const papara = await localStorage.getItem('mode') == 'papara' ? true : false
    setTransactionSummary({})
    const req = await axios.get(config.apiUrl + "transactions/gettransactionsummary", {
      params: {
        token: token,
        type: "deposit",
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        papara,
      }
    })
    setTransactionSummary(req.data)

    const req2 = await axios.get(config.apiUrl + "transactions/gettransactionsummary", {
      params: {
        token: token,
        type: "withdraw",
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
      }
    })
    setTransactionSummaryWithdraw(req2.data)
  }

  function formatMoney(n) {
    try {
      if (!n) return 0
      const x = parseFloat(n).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1.').replace(/\.(\d+)$/, ',$1');

      return x
    }
    catch (e) {
      return n
    }
  }

  useEffect(() => {
    // getDepositTransactions()
    getTransactionSummary()

    // const script = document.createElement("script");
    // script.src = "YatirimDataTable.js";
    // script.async = true;
    // document.body.appendChild(script);
  }, [])

  const validateRejectTransaction = async (status, _id, rejectReason = "") => {
    const req = await axios.post(config.apiUrl + "transactions/verifyrejectdeposit", {
      token: token,
      _id: _id,
      status: status ? 1 : 2,
      type: "deposit",
      rejectReason: rejectReason

    })
    window.location.reload()

  }



  return (
    <div className="main-content">
      <div className="container-fluid">
        <div className="row clearfix">
          {/* <div className="row" style={{ marginLeft: '10px' }}>
            {depositTransactions.length > 0 && <p>[{moment(depositTransactions[0].transactionTime).format("DD/MM/YYYY")} - {moment(depositTransactions[depositTransactions.length - 1].transactionTime).format("DD/MM/YYYY")}] -&gt; Gönderilen Tutar: {formatMoney(transactionSummary?.amount)} TL | İşlem Adedi: {transactionSummary?.number || 0} </p>}
          </div> */}
          <div style={{ width: '100%', marginBottom: '10px', padding: '10px' }}>

            <a href="manuelyatirim">
              {getProfileInformation.type == 'admin' && <button type="button" className="btn btn-primary" style={{ float: 'right', display: 'flex', justifyContent: 'end', marginRight: '110px' }}>
                Manuel Yatırım
              </button>}
            </a>

            <div className="col-md-12">
              <div class="card">
                <h5 class="card-header">İşlem Filtreleme</h5>
                <div class="card-body" className=''>

                  <div class="row pl-20 pr-20">



                    <div class="form-group col-md-2">
                      <label class="control-label">Tipi</label>
                      <div>
                        <select id="banka" value={state?.type} onChange={(e) => _setState({ type: e.target.value })} name="banka" class="form-control">
                          <option value="all">Hepsi</option>
                          <option value="deposit">Yatırım İşlemleri</option>
                          <option value="withdraw">Çekim İşlemleri</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group col-md-2">
                      <label class="control-label">Durumu</label>
                      <div>
                        <select id="banka" name="banka" value={state?.status} onChange={(e) => _setState({ status: e.target.value })} class="form-control">
                          <option value="all" >Hepsi</option>
                          <option value="1">Onaylanmış</option>
                          <option value="2">Reddedilmiş</option>
                        </select>
                      </div>
                    </div>

                    <div class="form-group col-md-2">
                      <label class="control-label">Kullanıcı Ad Soyad</label>
                      <div>
                        <input type="text" class="form-control input" name="" value={state?.nameSurname} onChange={(e) => _setState({ nameSurname: e.target.value })} />
                      </div>
                    </div>

                    <div class="form-group col-md-2">
                      <label class="control-label">Kullanıcı Id</label>
                      <div>
                        <input type="text" class="form-control input" name="" value={state?.user_id} onChange={(e) => _setState({ user_id: e.target.value })} />
                      </div>
                    </div>

                    <div class="form-group col-md-2">
                      <label class="control-label">Website</label>
                      <div>
                        <input type="text" class="form-control input" name="" value={state?.website} onChange={(e) => _setState({ website: e.target.value })} />
                      </div>
                    </div>

                    <div class="form-group col-md-1">
                      <label class="control-label">Tutar Min. </label>
                      <div>
                        <input type="number" class="form-control input" name="" value={state?.price_min} onChange={(e) => _setState({ price_min: e.target.value })} />
                      </div>
                    </div>

                    <div class="form-group col-md-1">
                      <label class="control-label">Tutar Max. </label>
                      <div>
                        <input type="number" class="form-control input" value={state?.price_max} onChange={(e) => _setState({ price_max: e.target.value })} name="" />
                      </div>
                    </div>




                    <div class="form-group col-md-4">
                      <label class="control-label">Tarih Aralığı</label>
                      {showDateRangePicker && <button className="btn btn-danger ml-10" onClick={() => setShowDateRangePicker(false)}>X (Kapat)</button>}
                      {showDateRangePicker && <><DateRangePicker
                        locale={rdrLocales.tr}
                        ranges={[selectionRange]}
                        onChange={(value) => {
                          setSelectionRange(value.selection);
                          getDepositTransactions(value.selection)
                          // setShowDateRangePicker(false)
                          // window.location.href = '/?startDate=' + moment(value.selection.startDate).format('YYYY-MM-DD') + '&endDate=' + moment(value.selection.endDate).format('YYYY-MM-DD')

                        }}
                      /></>}
                      <div>
                        <button type="button" className="form-control input" onClick={() => setShowDateRangePicker(prev => !prev)}>
                          {moment(selectionRange?.startDate).format('DD/MM-YYYY')} - {moment(selectionRange?.endDate).format('DD/MM-YYYY')} </button>
                      </div>
                    </div>





                  </div>

                </div>
              </div>

            </div>




          </div >

          <div className="col-md-12">
            <div class="card">
              <h5 class="card-header">İşlemler</h5>
              <div class="card-body" className=''>
                <div class="row ml-20 mr-20" style={{ overflow: 'auto' }}>
                  <table id="example" className="table" style={{ width: '100%' }}>
                    <thead>
                      <tr>
                        <th>Website</th>
                        <th>TİPİ</th>
                        <th>AD SOYAD</th>
                        <th>MİKTAR</th>
                        <th>BANKA</th>
                        <th>KULLANICI ADI</th>
                        <th>IP</th>
                        <th>DURUM</th>
                        <th>TESLİM ZAMANI</th>
                        <th>İŞLEM ZAMANI</th>
                        <th>ONAY/İPTAL KODU</th>
                      </tr>
                    </thead>
                    <tbody>
                      {depositTransactions.map((transaction, index) =>
                        <tr>
                          <td style={{ maxWidth: 200 }}>{transaction.websiteCodeS} - {transaction?.transactionId}</td>
                          <td style={{ textAlign: 'center' }}><button type="button" className={"btn btn-info"}>{transaction.type == "deposit" ? "Yatırım" : "Çekim"}</button> </td>
                          <td>{transaction.nameSurname}</td>
                          <td>{formatMoney(transaction.amount)} TL</td>
                          <td>{transaction.bankAccountId?.bankName} - {transaction.bankAccountId?.nameSurname}</td>
                          <td style={{ textAlign: 'center' }}>{transaction?.user_id}</td>
                          <td style={{ textAlign: 'center' }}>{transaction?.ipaddress}</td>
                          <td>

                            {(getProfileInformation.type == 'super_admin') && <button type="button" onClick={() => window.location = '/admintransactionedit?_id=' + transaction._id} className="btn btn-info"><i className="ik ik-check-circle" />Düzenle</button>}
                            {(getProfileInformation.type == 'super_admin') && <button type="button" onClick={() => {

                              axios.post(config.apiUrl + 'transactions/banip', { _id: transaction._id }).then(res => {
                                if (res.data) {
                                  alert('İşlem Başarıyla Silindi')
                                  window.location.reload()
                                }
                              })

                            }} className="btn btn-warning"><i className="ik ik-check-circle" />İp Banla</button>}
                            {transaction.status == 0 &&
                              <>
                                <div style={{ float: 'left' }}>
                                  <button type="button" style={{ paddingLeft: '20px' }} onClick={() => validateRejectTransaction(true, transaction._id)} className="btn btn-success"><i className="ik ik-check-circle" />Onayla</button>
                                </div>
                                <div style={{ float: 'left' }}>
                                  <button type="button" style={{ backgroundColor: 'red' }} onClick={() => {
                                    let rejectReason = prompt("Red Nedenini giriniz");
                                    validateRejectTransaction(false, transaction._id, rejectReason)

                                  }} className="btn btn-success"><i className="ik ik-check-circle" />Red Ver</button></div>
                              </>
                            }
                            {transaction.status == 1 && <b><font color='green'>ONAYLANDI</font></b>}
                            {transaction.status == 2 && <b><font color='red'>REDDEDİLDİ</font></b>}

                          </td>


                          <td>{moment(transaction.createdAt).format('YYYY-MM-DD HH:mm')}</td>
                          <td>{transaction.status != 0 && moment(transaction.updatedAt).format('YYYY-MM-DD HH:mm')}</td>
                          <td style={{ textAlign: 'center' }}>{transaction.rejectReason}</td>
                        </tr>


                      )}


                    </tbody>
                  </table>

                </div>
              </div>
            </div>
          </div>


        </div >

      </div >
    </div >
  );
}

export default App;
