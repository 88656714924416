
import { useState, useEffect } from "react"
import axios from "axios"
import config from "../config.json"
import Cookies from "js-cookie";
import $ from 'jquery';
import DataTable from 'datatables.net';
import moment from 'moment';
import { PERMISSIONS, checkPermission } from "../consts/Permissions"

export default function App() {
    const userProfile = JSON.parse(localStorage.getItem('profile'))
    const token = Cookies.get('token')
    const [formData, setFormData] = useState({
        username: "",
        firstName: "",
        lastName: "",
        password: "",
    })
    const formHandler = (inputName, value, number = false) => {
        setFormData(prev => {
            const newDataForm = new Object({ ...formData })
            if (number)
                newDataForm[inputName] = Number(value)
            else
                newDataForm[inputName] = value

            return newDataForm
        }

        )
    }

    const createUser = () => {
        axios.post(config.apiUrl + "users/createuser", {
            token: token,
            ...formData,
            remoteToken: localStorage.getItem('remoteToken')
        }).then(() => { alert("Başarılı bir şekilde eklendi"); window.location = '/kullanicilar' }).catch(e => alert("Ekleme sırasında hata" + e))
    }


    useEffect(() => {

    }, [])

    if (!checkPermission(userProfile, PERMISSIONS.USER_MANAGEMENT.key))
        return (<>No permission</>)


    return (
        <div className="main-content">
            <div className="container-fluid">
                <div className="page-header">
                    <div className="row align-items-end">
                        <div className="col-lg-4">
                            <nav className="breadcrumb-container" aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="#"><i className="ik ik-home" /></a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <a href="#">Kullanıcı Aç</a>
                                    </li>

                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header"><h3>Kullancı Ekle</h3></div>
                            <div className="card-body">
                                <form className="forms-sample">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail3">Kullanıcı Adı</label>
                                                <input type="text" className="form-control is-invalid" id="exampleInputText3" placeholder="Kullanıcı Adı" onChange={(e) => formHandler("username", e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail3">Şifre</label>
                                                <input type="password" className="form-control is-invalid" id="exampleInputNumber3" placeholder="Şifre" onChange={(e) => formHandler("password", e.target.value)} />
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail3">Ad</label>
                                                <input type="text" className="form-control is-invalid" id="exampleInputNumber3" placeholder="Ad" onChange={(e) => formHandler("firstName", e.target.value)} />
                                            </div>
                                        </div>


                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail3">Soyad</label>
                                                <input type="text" className="form-control is-invalid" id="exampleInputEmail3" placeholder="Açık İsim" onChange={(e) => formHandler("lastName", e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <button type="submit" className="btn btn-primary mr-2" onClick={() => createUser()}>Submit</button>
                                <button className="btn btn-light">Cancel</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}