import "./ExternalYatirim/css/style.css";
import "./ExternalYatirim/external.common.css";
import DatePicker from "react-datepicker";
import "./ExternalYatirim/css/montserrat-font.css";
import "./ExternalYatirim/fonts/material-design-iconic-font/css/material-design-iconic-font.min.css";
import bgImage from "./ExternalYatirim/images/wizard-v10-bg.jpg";
import config from "../config.json";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import $ from "jquery";
import moment from "moment";
import Countdown from "react-countdown";
import bankAccounts from "../banks.json";
import io from "socket.io-client";
import bankImage from "./ExternalYatirim/images/bank.jpeg";
import paparaImage from "./ExternalYatirim/images/papara.png";
import QRCode from "react-qr-code";

function userNameEncoder(name) {
  try {
    return name?.replace(/_/g, " ");
  } catch (e) {
    return name;
  }
}

const urlParams = new URLSearchParams(window.location.search);
const hashed = urlParams.get("token");
const nameSurname = userNameEncoder(urlParams.get("name"));
const amount = urlParams.get("amount");
const redirect_url = urlParams.get("redirect_url");

function App({ paparaPage }) {
  const LAST_STEP = 3;
  const [formData, setFormData] = useState(
 {   ...(amount? {amount}: undefined)}
    
);
  const [step, setStep] = useState(1);
  const [bankType, setBankType] = useState(paparaPage ? "papara" : "banka");
  const [bankAccount, setBankAccount] = useState(false);
  const [datenow, setDatenow] = useState(Date.now());
  const [loading, setLoading] = useState(false);

  const handleInput = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const getBankAccount = async (papara = false) => {
    const req = await axios.get(
      config.apiUrl + "transaction-encription/get-bank",
      {
        params: {
          hashed: hashed,
          bankName: formData?.bank || "",
          amount: formData?.amount,
        },
      },
    );
    if (req.data?.getBank) {
      setBankAccount(req.data?.getBank);
      return req.data?.getBank;
    } else {
      setBankAccount(false);
      return false;
    }
  };

  const [ip, setIP] = useState("");

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };

  useEffect(() => {
    //passing getData method to the lifecycle method
    getData();
  }, []);

  const makeDepositRequest = async () => {
    setLoading(true);
    try {
      if (formData?.amount < 50) return alert("Yatırım En az 50 TL olmalıdır");
      const req = await axios.post(
        config.apiUrl + "transactions/makedepositrequest",
        {
          hashed: hashed,
          amount: formData?.amount || 0,
          bankAccountId: bankAccount?._id || "",
          ipaddress: ip,
          papara: bankType == "papara",
        },
      );
      // alert("YATIRIM TALEBİNİZ GÖNDERİLDİ. TEŞEKKÜRLER")
      // window.location.href = "about:blank"

      if (req.data.timeout) {
        return alert("Lütfen 5 dakika sonra tekrar deneyiniz");
      }
      if (req.data.message == "Transaction id already exists") {
        return alert(
          "Bu işlem daha önce yapılmıştır. Lütfen yeni bir işlem ile tekrar deneyiniz",
        );
      }

      if (redirect_url) {
        window.location.href = redirect_url;
      } else {
        // document.write("YATIRIM TALEBİNİZ GÖNDERİLDİ. TEŞEKKÜRLER. SAYFAYI KAPATABİLİRSİNİZ")
      }

      // window.location.href = callback
    } catch (e) {
      alert("HATA OLDU");
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const copyText = (text, inputId) => {
    navigator.clipboard.writeText(text);
    //make input color green
    document.getElementById(inputId).style.color = "green";
    // //change text of button to copied

    setTimeout(() => {
      document.getElementById(inputId).style.color = "black";
    }, 2000);
  };
  const renderStep = (step, papara) => {
    switch (step) {
      case 1:
        return (
          <div>
            <div className="row">
              <div className="col-md-12">
                <h1 className="welcome-title">Merhaba, {nameSurname}</h1>
                <p className="welcome-text">
                  Lütfen aşağıdan yatırım yapmak istediğiniz tutarı ve ödeme
                  yönemini seçiniz.
                </p>
              </div>
            </div>

            <div className="row">
              {!paparaPage && (
                <div
                  class="col-md-6"
                  style={{
                    filter:
                      config?.bankEnabled == false ? "blur(3px)" : "blur(0px)",
                  }}
                >
                  <div
                    className={`col-md-12 option-box ${
                      bankType == "banka" ? "selected" : ""
                    }`}
                    onClick={() =>
                      config?.bankEnabled != false && setBankType("banka")
                    }
                  >
                    <div>
                      <img src={bankImage} className="option-box-image" />
                    </div>
                    <div className="option-box-title">
                      Banka Transferi Yatırım
                    </div>
                  </div>
                </div>
              )}
              {paparaPage && (
                <div
                  class="col-md-6"
                  style={{
                    filter:
                      config?.paparaEnabled == true ? "blur(0)" : "blur(3px)",
                  }}
                >
                  <div
                    className={`col-md-12 option-box ${
                      bankType == "papara" ? "selected" : ""
                    }`}
                    onClick={() =>
                      config?.paparaEnabled && setBankType("papara")
                    }
                  >
                    <div>
                      <img src={paparaImage} className="option-box-image" />
                    </div>
                    <div className="option-box-title">Papara Yatırım</div>
                  </div>
                </div>
              )}
            </div>

            <div className="row" style={{ marginTop: 20 }}>
              <div className="col-md-12">
                {bankType == "banka" && (
                  <div className="form-holder form-holder-2">
                    <select
                      className="form-control amount-input"
                      required
                      onChange={(e) => handleInput("bank", e?.target.value)}
                    >
                      <option value="" disabled selected>
                        Banka Seçiniz
                      </option>
                      {bankAccounts.bankAccounts.map((e) => (
                        <option>{e}</option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
            </div>

            <div className="row" style={{ marginTop: 20 }}>
              <div className="col-md-12">
                <div class="input-group mb-3">
                  <input
                    type="number"
                    class="form-control amount-input"
                    pattern="[0-9]*"
                    placeholder="Yatırım Tutarı Girin"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    disabled={amount ? true : false}
                    defaultValue={amount || null}
                    onChange={(e) => handleInput("amount", e?.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            <div className="method-image">
              <img
                src={bankType == "banka" ? bankImage : paparaImage}
                className="option-box-image"
              />
            </div>
            <div className="row">
              <div className="col-md-12">
                <h1 className="welcome-title">Yatırım Gerçekleştirme</h1>
                <p className="welcome-text">
                  Lütfen aşağıdaki bilgilere göre yatırımınızı belirtilen süre
                  içerisinde yapın. Yatırımı yaptıkdan sonra{" "}
                  <b>"Yatırımı Yaptım"</b> butonuna tıklayabilirsiniz.
                </p>
              </div>
            </div>

            {bankType == "papara" && (
              <div className="method-image" style={{ margin: 20 }}>
                <QRCode
                  size={150}
                  style={{
                    height: "auto",
                    maxWidth: "30%",
                    width: "30%",
                    minWidth: 200,
                    minHeight: 200,
                  }}
                  value={
                    "https://www.papara.com/personal/qr?accountNumber=" +
                    bankAccount?.accountNumber +
                    "&" +
                    "amount=" +
                    formData?.amount
                  }
                  viewBox={`0 0 256 256`}
                />
              </div>
            )}

            <div className="row">
              <div className="col-md-4">
                <input
                  type="text"
                  name="comment"
                  className="form-control amount-input no-cursor"
                  disabled
                  value={"Banka Adı"}
                />
              </div>
              <div className="col-md-8">
                <input
                  type="text"
                  name="comment"
                  className="form-control amount-input no-cursor"
                  disabled
                  value={bankAccount?.bankName}
                />
              </div>
            </div>

            <div className="row" style={{ marginTop: 15 }}>
              <div className="col-md-4">
                <input
                  type="text"
                  name="comment"
                  className="form-control amount-input no-cursor"
                  disabled
                  value={"Alıcı Adı Soyadı"}
                />
              </div>
              <div className="col-md-8">
                <div class="input-group">
                  <input
                    type="text"
                    id="nameSurname"
                    name="comment"
                    className="form-control amount-input no-cursor inputstyle"
                    disabled
                    value={bankAccount?.nameSurname}
                  />
                  <div class="input-group-append">
                    <button
                      style={{
                        marginLeft: 0,
                        background: "transparent",
                        borderWidth: 0,
                        width: 0,
                      }}
                      onClick={() =>
                        copyText(bankAccount?.nameSurname, "nameSurname")
                      }
                    >
                      <img
                        class="avatar"
                        style={{ height: 25, width: 25 }}
                        src="img/paste.png"
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <input
                  type="text"
                  name="comment"
                  className="form-control amount-input no-cursor"
                  disabled
                  value={"Hesap Numarası"}
                />
              </div>
              <div className="col-md-8">
                <div class="input-group">
                  <input
                    type="text"
                    name="comment"
                    id="accountNumber"
                    className="form-control amount-input no-cursor inputstyle"
                    disabled
                    value={bankAccount?.accountNumber}
                  />
                  <div class="input-group-append">
                    <button
                      style={{
                        marginLeft: 0,
                        background: "transparent",
                        borderWidth: 0,
                        width: 0,
                      }}
                      onClick={() =>
                        copyText(bankAccount?.accountNumber, "accountNumber")
                      }
                    >
                      <img
                        class="avatar"
                        style={{ height: 25, width: 25 }}
                        src="img/paste.png"
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {bankType == "banka" && (
              <div className="row">
                <div className="col-md-4">
                  <input
                    type="text"
                    name="comment"
                    className="form-control amount-input no-cursor"
                    disabled
                    value={"IBAN Adresi"}
                  />
                </div>
                <div className="col-md-8">
                  <div class="input-group">
                    <input
                      type="text"
                      id="iban"
                      name="comment"
                      className="form-control amount-input no-cursor inputstyle"
                      disabled
                      value={bankAccount?.iban
                        ?.replace(/[^\dA-Z]/g, "")
                        .replace(/(.{4})/g, "$1 ")
                        .trim()}
                      style={{ fontSize: "clamp(0px, 3.4vw, 18px)" }}
                    />
                    <div class="input-group-append">
                      <button
                        style={{
                          marginLeft: 0,
                          background: "transparent",
                          borderWidth: 0,
                          width: 0,
                        }}
                        onClick={() => copyText(bankAccount?.iban, "iban")}
                      >
                        <img
                          class="avatar"
                          style={{ height: 25, width: 25 }}
                          src="img/paste.png"
                          alt=""
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {bankType == "papara" && (
              <div className="row" style={{ marginTop: 10 }}>
                <div className="col-md-12">
                  <button
                    className="btn btn-success title"
                    style={{ width: "100%", height: 50, color: "#FFF" }}
                    onClick={() =>
                      window.open(
                        "https://www.papara.com/personal/qr?accountNumber=" +
                          bankAccount?.accountNumber +
                          "&" +
                          "amount=" +
                          formData?.amount,
                        "_blank",
                      )
                    }
                  >
                    Papara'yı Aç
                  </button>
                </div>
              </div>
            )}
          </div>
        );
      case 3:
        return (
          <div className="row">
            <div className="col-md-12">
              <h1 className="welcome-title">Yatırım Talebi gönderildi</h1>
              <p className="welcome-text">
                Yatırım talebiniz başarıyla gönderildi. Sayfayı
                kapatabilirsiniz. Onaylandığı zaman hesabınıza yansıyacaktır.
              </p>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const handleNextStep = async () => {
    if (step == LAST_STEP) {
      // close page or redirect
      window.close();
    } else if (step == 1) {
      if (
        !formData?.amount ||
        !bankType ||
        (bankType == "banka" && !formData?.bank)
      ) {
        console.log(
          !formData?.amount,
          !bankType,
          bankType == "banka" && !formData?.bank,
        );
        alert("Boş alanları doldurun");
      } else {
        const bankName = await getBankAccount(bankType == "papara");
        if (!bankName) {
          return alert(
            `Seçtiğiniz kriterlerde uygun bir ${
              bankType == "papara" ? "papara" : "banka"
            } hesabı bulunamadı, miktarı değiştirin veya daha sonra tekrar deneyin`,
          );
        } else {
          setStep((prev) => prev + 1);
        }
      }
    } else if (step == 2) {
      makeDepositRequest(bankType == "papara");
      setStep((prev) => prev + 1);
    } else {
      setStep((prev) => prev + 1);
    }
  };
  const handlePreviousStep = () => {
    if (step != 1) {
      setStep((prev) => prev - 1);
    }
  };

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      // alert('bitti')
      window.location.href = "about:blank";
    } else {
      // Render a countdown
      return (
        <span>
          {minutes < 10 ? "0" + minutes : minutes}:
          {seconds < 10 ? "0" + seconds : seconds}
        </span>
      );
    }
  };
  return (
    <div className="body">
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap"
        rel="stylesheet"
      ></link>
      <div className="container" style={{ maxWidth: 720 }}>
        <div className="row">
          <div className="col-md-12">
            <div className="card mt-5">
              <div className="card-header">
                <div
                  className="row"
                  style={{ width: "100%", alignItems: "center" }}
                >
                  <div
                    className={`col - md - 4 title - header ${
                      step == 1 ? "selectedCard" : ""
                    } `}
                  >
                    Yöntem Seçme
                  </div>
                  <div
                    className={`col - md - 4 text - center title - header ${
                      step == 2 ? "selectedCard" : ""
                    } `}
                  >
                    Yatırım Gerçekleştirme
                  </div>
                  <div
                    className={`col - md - 4 text - right title - header ${
                      step == 3 ? "selectedCard" : ""
                    } `}
                  >
                    Sonuçlandırma
                  </div>
                </div>
              </div>
              <div className="card-body">
                {renderStep(step)}

                <div className="row" style={{ marginTop: 10 }}>
                  <div className="col-md-12">
                    <button
                      className="btn btn-orange title"
                      style={{
                        width: "100%",
                        height: 50,
                        background: "#fe644f",
                        color: "#FFF",
                      }}
                      onClick={handleNextStep}
                    >
                      {step == 1
                        ? "İşlemi Başlat"
                        : step == 2
                        ? "Yatırımı Yaptım "
                        : step == LAST_STEP
                        ? "Bitir"
                        : "Devam Et"}
                      {step == 2 && (
                        <>
                          &nbsp;(
                          <Countdown
                            date={datenow + 300000 * 2}
                            renderer={renderer}
                          />
                          )
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div
          style={{
            position: "absolute",
            width: "100vw",
            height: "100vh",
            background: "#000000d4",
            left: 0,
            top: 0,
          }}
        ></div>
      )}
    </div>
  );
}

export default App;
