import { useState } from "react";
import "./General.css";
import axios from "axios";
import config from "../config.json";
import Cookies from "js-cookie";
import SwitchSelector from "react-switch-selector";
import { useEffect } from "react";

function App() {
  return (
    <div>
      <title>Launch</title>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      {/*===============================================================================================*/}
      <link rel="stylesheet" type="text/css" href="ui/css/util.css" />
      <link rel="stylesheet" type="text/css" href="ui/css/login.css" />
      {/*===============================================================================================*/}
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div
          className=""
          style={{ background: "white", textAlign: "center", padding: "20px" }}
        >
          <h1 style={{ fontSize: "40px", marginBottom: "15px" }}>
            Bizimle iletişime geçmek için:
          </h1>
          <br />
          <a href="mailto:mastermetroxmail@gmail.com"
            style={{
              background: "white",
              textAlign: "center",
              padding: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
              <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
            </svg>
            <h1 style={{ fontSize: "40px", marginLeft: "5px" }}>mastermetroxmail@gmail.com</h1>
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
