

import { useState, useEffect } from "react"


function LoadingComponent({message }) {


    return (
        <div style={{ width: '100%', height: '100%', position: 'absolute', left: 0, top: 0, background: '#000000ad', zIndex: '999999', display: 'flex', justifyContent: 'center', alignItems: 'center' }} id="loadingSpinner">
      
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',flexDirection:'column' }}>
                <img src="../img/loading.gif" class="rounded-circle" alt="" width={50} height={50} />
                <p style={{color:'#FFF', marginTop:20, fontSize:20}}>{message ? message : "Lütfen Bekleyin.."}</p>
                </div>
        </div>
    );
}

export default LoadingComponent;
